<div
  class="rounded-3xl px-4 py-3"
  [ngClass]="{
    'bg-[#eaf6f2]': balanceItem.currency === CryptoSymbol.AbstractUsdt,
    'bg-[#fee6ea]': balanceItem.currency === CryptoSymbol.Trx,
    'bg-[#f3edfd]': balanceItem.currency === CryptoSymbol.Matic,
    'bg-[#e6f4fa]': balanceItem.currency === CryptoSymbol.Ton,
    'bg-[#f3f5f7]': balanceItem.currency === CryptoSymbol.Not,
    'bg-[#fff5e9]': balanceItem.currency === CryptoSymbol.Bitcoin
  }"
>
  <app-currency-icon [currency]="balanceItem.currency" size="sm"></app-currency-icon>

  <div class="mt-6">
    <p class="text-2xl font-semibold">
      {{ balanceItem.availableAmount | mfeCustom: { currency: balanceItem.currency } }}
      <span class="text-sm text-text-secondary">{{ currencyName }}</span>
    </p>
  </div>

  <hr class="my-3 border-slate-400/20" />

  <div class="text-sm font-medium text-text-secondary">
    {{ uzsValue | mfeCustom: { currency: CryptoSymbol.Uzs } }} UZS
  </div>
</div>

<!-- <div
  class="h-full rounded-main border border-link-color dark:border-slate-700 dark:bg-slate-800"
  [ngClass]="isShort ? 'px-5 py-3' : 'min-h-[8.75rem] px-7 py-5'"
>
  <div
    *ngIf="!isLoading; else balanceSkeleton"
    class="flex gap-5"
    [ngClass]="isShort ? 'items-center' : 'items-start'"
  >
    <app-currency-icon [currency]="balanceItem.currency" />

    <div class="space-y-2">
      <div class="flex items-start gap-2">
        <h4 class="subtitle">{{ "Wallet.Balance" | translate }} {{ getCurrencyCaption() }}</h4>
        <span
          *ngIf="
            !isShort &&
            (balanceItem.currency === CryptoSymbol.Usdt ||
              balanceItem.currency === CryptoSymbol.PolygonUsdt ||
              balanceItem.currency === CryptoSymbol.TonUsdt)
          "
          class="rounded-xl bg-tron px-2 py-1 text-base text-text-secondary"
          [ngClass]="{
            'bg-tron': balanceItem.currency === CryptoSymbol.Usdt,
            'bg-polygon': balanceItem.currency === CryptoSymbol.PolygonUsdt,
            'bg-ton': balanceItem.currency === CryptoSymbol.TonUsdt
          }"
        >
          {{ getNetworkName() }}
        </span>
      </div>

      <p class="text-2xl font-bold">
        <span>
          {{ balanceItem.amount | mfeCustom: { currency: balanceItem.currency } }} {{ getCurrencyName() }}
        </span>
      </p>
      <p *ngIf="!isShort" class="subtitle">
        ({{ getUzsValue(balanceItem.amount) | mfeCustom: { currency: CryptoSymbol.Uzs } }} UZS)
      </p>
      <p *ngIf="showRate" class="subtitle">
        1 {{ getCurrencyName() }} = {{ getUzsValue(1) | mfeCustom: { currency: CryptoSymbol.Uzs } }} UZS
      </p>
    </div>

    <button
      *ngIf="withSelect"
      class="ml-auto flex h-8 w-8 items-center justify-center rounded-main border-2 border-accent"
      (click)="onClickSelect()"
    >
      <span *ngIf="selected" class="aspect-square w-4 rounded-full bg-accent"></span>
    </button>
  </div>

  <ng-template #balanceSkeleton>
    <div class="flex items-start gap-5">
      <app-skeleton class="h-16 w-16" />
      <div class="space-y-2">
        <app-skeleton class="h-8 w-52" />
        <app-skeleton class="h-8 w-28" />
        <app-skeleton *ngIf="!isShort" class="h-8 w-28" />
      </div>
    </div>
  </ng-template>
</div> -->

import { Injectable } from "@angular/core";
import { SuccessLoginDto } from "src/app/common/DTO/auth/success-login.dto";
import { UserDto } from "src/app/common/DTO/users/user.dto";
import { LanguageConstants } from "src/app/common/constants/language.constants";
import { StorageConstants } from "src/app/common/constants/storage.constants";
import { AdminPermission } from "src/app/common/enums/admin-permission.enum";
import { PlatformType } from "src/app/common/enums/platform-type.enum";

@Injectable({
  providedIn: "root",
})
export class LocalStorageService {
  private _dbName = "appDb";
  private _dbVersion = 1;

  constructor() {}

  public async saveUserData(dto: UserDto) {
    await this.saveByKey(dto, StorageConstants.UserData);
  }

  public getUserData(): Promise<UserDto | null> {
    return this.getByKey(StorageConstants.UserData);
  }

  public async savePlatformType(type: PlatformType) {
    await this.saveByKey(type, StorageConstants.PlatformType);
  }

  public async getPlatformType(): Promise<PlatformType> {
    const type = await this.getByKey(StorageConstants.PlatformType);

    if (type == null) {
      await this.savePlatformType(PlatformType.DEPOSITARY);
      return PlatformType.DEPOSITARY;
    }

    return type;
  }

  public async removeUserData() {
    await this.deleteByKey(StorageConstants.UserData);
  }

  public async saveTokens(dto: SuccessLoginDto) {
    // in future: we can save all this fields as 1 record
    await this.saveByKey(dto.accessToken, StorageConstants.TokenKey);
    await this.saveByKey(dto.accessExpiryAt, StorageConstants.TokenLifetimeKey);
    await this.saveByKey(dto.refreshToken, StorageConstants.RefreshKey);
    await this.saveByKey(dto.refreshExpiryAt, StorageConstants.RefreshLifetimeKey);
  }

  public async clearTokens() {
    await this.deleteByKey(StorageConstants.TokenKey);
    await this.deleteByKey(StorageConstants.TokenLifetimeKey);

    await this.deleteByKey(StorageConstants.RefreshKey);
    await this.deleteByKey(StorageConstants.RefreshLifetimeKey);
  }

  public async saveLanguage(lang: LanguageConstants) {
    await this.saveByKey(lang, StorageConstants.Language);
  }

  public accessToken() {
    return this.getByKey(StorageConstants.TokenKey);
  }

  public refreshToken() {
    return this.getByKey(StorageConstants.RefreshKey);
  }

  public async accessTokenLifetime(): Promise<Date | null> {
    const date = await this.getByKey(StorageConstants.TokenLifetimeKey);
    if (date == null) {
      return date;
    }

    return new Date(date);
  }

  public async refreshTokenLifetime(): Promise<Date | null> {
    const date = await this.getByKey(StorageConstants.RefreshLifetimeKey);
    if (date == null) {
      return date;
    }

    return new Date(date);
  }

  public async getLanguage(): Promise<LanguageConstants> {
    const lang = await this.getByKey(StorageConstants.Language);
    if (lang == null) {
      await this.saveLanguage(LanguageConstants.RUSSIAN);
      return LanguageConstants.RUSSIAN;
    }

    return lang;
  }

  public async isSuperAdmin() {
    const user = await this.getUserData();
    return user?.role.name === "SuperAdmin";
  }

  public async adminHasPermission(permission: AdminPermission) {
    const user = await this.getUserData();
    return user?.role.permissions.includes(permission) || false;
  }

  public async saveSidebarState(isFullMenu: boolean) {
    await this.saveByKey(isFullMenu, StorageConstants.SidebarState);
  }

  public async getSidebarState(): Promise<boolean> {
    const state = await this.getByKey(StorageConstants.SidebarState);
    if (state == null) {
      await this.saveSidebarState(true);
      return true;
    }

    return state;
  }

  public async setIsWebVerification(isWebVerification: boolean) {
    await this.saveByKey(isWebVerification, StorageConstants.isWebVerification);
  }

  public async resetIsWebVerification() {
    await this.deleteByKey(StorageConstants.isWebVerification);
  }

  public async checkIfWebVerification(): Promise<boolean> {
    const isWebVerification = await this.getByKey(StorageConstants.isWebVerification);
    return Boolean(isWebVerification);
  }

  private async getContext(): Promise<IDBDatabase> {
    return new Promise((resolve, reject) => {
      const connection = indexedDB.open(this._dbName, this._dbVersion);

      connection.onupgradeneeded = ev => {
        const ctx = connection.result;
        if (!ctx.objectStoreNames.contains(this._dbName)) {
          ctx.createObjectStore(this._dbName);
        }

        resolve(connection.result);
      };

      connection.onerror = () => {
        console.error(connection.error);
        reject(new Error("Internal database error"));
      };

      connection.onsuccess = () => {
        const ctx = connection.result;
        ctx.onversionchange = () => {
          ctx.close();
          reject(new Error("Database version is too old.. refresh page"));
        };

        resolve(connection.result);
      };
    });
  }

  private getByKey(key: string): Promise<any | string> {
    return new Promise<string | null>(async (resolve, reject) => {
      const ctx = await this.getContext();
      const tx = ctx.transaction(this._dbName, "readonly");
      const dbSet = tx.objectStore(this._dbName);

      const request = dbSet.get(key);

      request.onsuccess = () => {
        resolve(request.result ?? null);
      };
      request.onerror = () => {
        reject(null);
      };
    });
  }

  private saveByKey(value: any, key: string) {
    return new Promise<void>(async resolve => {
      const ctx = await this.getContext();
      const tx = ctx.transaction(this._dbName, "readwrite");
      const dbSet = tx.objectStore(this._dbName);

      const request = dbSet.put(value, key);

      request.onsuccess = () => {
        resolve();
      };
      request.onerror = () => {
        resolve();
      };
    });
  }

  private async deleteByKey(key: string) {
    const ctx = await this.getContext();
    const tx = ctx.transaction(this._dbName, "readwrite");
    const dbSet = tx.objectStore(this._dbName);

    dbSet.delete(key);
  }
}

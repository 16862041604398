import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { UserDto } from "src/app/common/DTO/users/user.dto";
import { AdminPermission } from "src/app/common/enums/admin-permission.enum";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { UserActivationFilterOptions } from "src/app/common/enums/user-activation-filter-option.enum";
import { UserIdentificationFilterOptions } from "src/app/common/enums/user-identification-filter-options.enum";
import { DeactivateUserModalComponent } from "src/app/components/_admin/deactivate-user-modal/deactivate-user-modal.component";
import { DeleteUserKycModalComponent } from "src/app/components/_admin/delete-user-kyc-modal/delete-user-kyc-modal.component";
import { BlackListService } from "src/app/services/black-list.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { ReportsService } from "src/app/services/reports.service";
import { UserService } from "src/app/services/user.service";

interface IUserIdentificationFilter {
  name: string;
  value: UserIdentificationFilterOptions;
}

interface IUserActivationFilter {
  name: string;
  value: UserActivationFilterOptions;
}

@Component({
  templateUrl: "./admin-users.component.html",
  styleUrls: ["./admin-users.component.css"],
})
export class AdminUsersComponent implements OnInit {
  public users: UserDto[] = [];
  public page = 1;
  public pageSize = 6;
  public totalCount = 0;

  public userIdentificationFilter: IUserIdentificationFilter[] = [
    {
      name: this._translateService.instant("Admin.Users.Select_all"),
      value: UserIdentificationFilterOptions.All,
    },
    {
      name: this._translateService.instant("Admin.Users.Select_verified"),
      value: UserIdentificationFilterOptions.Verified,
    },
    {
      name: this._translateService.instant("Admin.Users.Select_not_verified"),
      value: UserIdentificationFilterOptions.NotVerified,
    },
  ];

  public userActivationFilter: IUserActivationFilter[] = [
    {
      name: this._translateService.instant("Admin.Users.Select_all"),
      value: UserActivationFilterOptions.All,
    },
    {
      name: this._translateService.instant("Admin.Users.Select_active"),
      value: UserActivationFilterOptions.Active,
    },
    {
      name: this._translateService.instant("Admin.Users.Select_not_active"),
      value: UserActivationFilterOptions.NotActive,
    },
  ];

  public selectedIdentificationFilter: UserIdentificationFilterOptions =
    this.userIdentificationFilter[0].value;
  public selectedActivationFilter: UserActivationFilterOptions = this.userActivationFilter[0].value;

  public search: string = "";

  public isUserActionsInProgress: boolean = false;
  public hasInteractionPermissions: boolean = false;

  constructor(
    private readonly _userService: UserService,
    private readonly _translateService: TranslateService,
    private readonly _blackListService: BlackListService,
    private readonly _reportService: ReportsService,
    private readonly _modalService: NgbModal,
    private readonly _localStorage: LocalStorageService
  ) {}

  async ngOnInit(): Promise<void> {
    this.hasInteractionPermissions = await this._localStorage.adminHasPermission(
      AdminPermission.UserInteraction
    );
    await this.requestAllUsers();
  }

  public async requestAllUsers() {
    const res = await this._userService.getAllUsers(
      this.search,
      this.pageSize,
      this.page,
      this.selectedIdentificationFilter,
      this.selectedActivationFilter
    );
    this.users = res.params!.items;
    this.totalCount = res.params!.totalCount;
  }

  public onSearch() {
    if (this.search.trim() === "") {
      return;
    }
    this.page = 1;
    this.requestAllUsers();
  }

  public renderStatus(user: UserDto) {
    if (user.blackList?.isForever) {
      return "Admin.Kyc.Black_list";
    }
    if (user.blackList?.isForever === false) {
      return "Admin.Users.Status_blocked";
    }
    if (!user.kyc) {
      return "Admin.Users.Status_no_kyc";
    }
    if (user.kyc) {
      return "Admin.Users.Status_verified";
    }

    return "-";
  }

  public isActive(user: UserDto): string {
    return user.isActive
      ? this._translateService.instant("Admin.Users.Active")
      : this._translateService.instant("Admin.Users.Not_active");
  }

  public getUsdtBalanceByUser(user: UserDto) {
    return user.balances.find(b => b.currency == CryptoSymbol.AbstractUsdt)?.availableAmount;
  }

  public get userIdentificationFilterNames(): string[] {
    return this.userIdentificationFilter.map(filter => this._translateService.instant(filter.name));
  }

  public get userActivationFilterNames(): string[] {
    return this.userActivationFilter.map(filter => this._translateService.instant(filter.name));
  }

  public userFilterNameByIdentificationOption(option: UserIdentificationFilterOptions): string {
    return this._translateService.instant(
      this.userIdentificationFilter.filter(x => x.value == option)[0].name
    );
  }

  public userFilterNameByActivationOption(option: UserActivationFilterOptions): string {
    return this._translateService.instant(this.userActivationFilter.filter(x => x.value == option)[0].name);
  }

  public async onActivationFilterSelect(selectedItem: string) {
    this.selectedActivationFilter = this.userActivationFilter.filter(
      x => this._translateService.instant(x.name) == selectedItem
    )[0].value;
    await this.requestAllUsers();
  }

  public async onIdentificationFilterSelect(selectedItem: string) {
    this.selectedIdentificationFilter = this.userIdentificationFilter.filter(
      x => this._translateService.instant(x.name) == selectedItem
    )[0].value;
    await this.requestAllUsers();
  }

  public handleDownloadReport(user: UserDto) {
    this._reportService.getUserPersonalDataReport(
      user.id,
      user.kyc?.firstName ?? "",
      user.kyc?.lastName ?? ""
    );
  }

  public async handleBlockUnblock(user: UserDto) {
    this.isUserActionsInProgress = true;

    if (user.blackList) {
      await this.unblockUser(user.id);
    } else {
      await this.blockUser(user.id);
    }

    await this.requestAllUsers();
    this.isUserActionsInProgress = false;
  }

  public openDeleteKycConfirmation(user: UserDto) {
    const modalRef = this._modalService.open(DeleteUserKycModalComponent);
    modalRef.componentInstance.user = user;
    modalRef.componentInstance.onSuccess.subscribe(() => {
      this.requestAllUsers();
    });
  }

  public openDeactivateUserConfirmation(user: UserDto) {
    const modalRef = this._modalService.open(DeactivateUserModalComponent);
    modalRef.componentInstance.user = user;
    modalRef.componentInstance.onSuccess.subscribe(() => {
      this.requestAllUsers();
    });
  }

  private async blockUser(userId: number) {
    await this._blackListService.blockUser(userId, false);
  }

  private async unblockUser(userId: number) {
    await this._blackListService.unblockUser(userId);
  }
}

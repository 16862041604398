<section>
  <div class="flex items-center justify-between">
    <h3 class="text-2xl font-semibold">
      {{ "Admin.Users.Title" | translate }}
      <span class="ml-2 text-slate-400">{{ totalCount }}</span>
    </h3>

    <app-dropdown
      [items]="userIdentificationFilterNames"
      (onSelect)="onIdentificationFilterSelect($event)"
      class="ml-auto"
    >
      <button class="flex items-center gap-2">
        <p class="flex justify-items-start text-sm font-medium text-slate-400">
          {{ "Admin.Users.Status" | translate }}:
        </p>
        <div class="flex items-center gap-1 text-sm font-medium text-black dark:text-white">
          {{ userFilterNameByIdentificationOption(selectedIdentificationFilter) }}
          <app-svg-icon name="chevron" class="block w-3" />
        </div>
      </button>
    </app-dropdown>

    <div class="searchInput ml-6">
      <input
        type="search"
        name="userSearch"
        id="userSearch"
        placeholder="Поиск"
        [(ngModel)]="search"
        (keyup.enter)="onSearch()"
      />
      <button type="button" class="searchInput__btn" (click)="onSearch()">
        <app-svg-icon name="actions/search" />
      </button>
    </div>
  </div>

  <!-- <div class="grid grid-cols-3">
    <div>
      <app-dropdown [items]="userActivationFilterNames" (onSelect)="onActivationFilterSelect($event)">
        <button class="flex items-center gap-2">
          <p class="flex w-[12rem] justify-items-start">
            {{ "Admin.Users.Activity" | translate }}:
            {{ userFilterNameByActivationOption(selectedActivationFilter) }}
          </p>
          <img src="assets/icons/arrow.svg" alt="lang-arrow" class="w-3" />
        </button>
      </app-dropdown>
    </div>
  </div> -->

  <table class="adminTable mt-6">
    <thead>
      <tr>
        <th scope="col">{{ "Admin.Users.Phone_number" | translate }}</th>
        <th scope="col">{{ "Admin.Users.Status" | translate }}</th>
        <th scope="col">Баланс</th>
        <th scope="col">{{ "Admin.Users.Activity" | translate }}</th>
        <th scope="col">{{ "Admin.Users.Registration_date" | translate }}</th>
        <th scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let user of users"
        [ngClass]="{ 'pointer-events-none opacity-60': isUserActionsInProgress }"
      >
        <td>{{ user.phoneNumber | phoneDisplayer }}</td>
        <td>{{ renderStatus(user) | translate }}</td>
        <td>{{ getUsdtBalanceByUser(user) | mfeCustom }} USDT</td>
        <td>{{ isActive(user) }}</td>
        <td class="whitespace-pre">
          {{ user.createdAt | customDate: "full" }}
        </td>
        <td class="flex items-center gap-x-3">
          <div ngbDropdown class="dropdown">
            <button
              ngbDropdownToggle
              class="h-6 w-6 disabled:cursor-not-allowed disabled:opacity-50"
              [disabled]="!hasInteractionPermissions"
            >
              <app-svg-icon name="actions/menu-dots" />
            </button>
            <div ngbDropdownMenu>
              <button ngbDropdownItem (click)="handleDownloadReport(user)">
                <app-svg-icon name="admin/download" class="aspect-square w-5" />
                <span>{{ "Admin.Users.Download_user_data" | translate }}</span>
              </button>
              <button ngbDropdownItem (click)="openDeleteKycConfirmation(user)">
                <app-svg-icon name="trash-bin" class="aspect-square w-5" />
                <span>{{ "Admin.Users.Delete_identification" | translate }}</span>
              </button>
              <button ngbDropdownItem (click)="openDeactivateUserConfirmation(user)">
                <app-svg-icon name="admin/close-circle" class="aspect-square w-5" />
                <span>{{ "Admin.Users.Deactivate_account" | translate }}</span>
              </button>
              <button ngbDropdownItem (click)="handleBlockUnblock(user)">
                <app-svg-icon name="admin/lock" class="aspect-square w-5" />
                <span>
                  {{
                    user.blackList ? ("Admin.Users.Unblock" | translate) : ("Admin.Users.Block" | translate)
                  }}
                </span>
              </button>
            </div>
          </div>
          <a
            class="h-6 w-6"
            [routerLink]="user.id.toString()"
            [title]="'Admin.Users.Open_user_page' | translate"
          >
            <app-svg-icon name="actions/back" class="block h-6 w-6 rotate-180" />
          </a>
        </td>
      </tr>
    </tbody>
  </table>

  <ngb-pagination
    class="mt-6 block"
    [collectionSize]="totalCount"
    [(page)]="page"
    [pageSize]="pageSize"
    [maxSize]="5"
    (pageChange)="requestAllUsers()"
  >
  </ngb-pagination>
</section>

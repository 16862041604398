import { animate, AUTO_STYLE, state, style, transition, trigger } from "@angular/animations";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { RouteConstants } from "src/app/common/constants/route.constants";
import { BalanceDto } from "src/app/common/DTO/balances/balance.dto";
import { RateDto } from "src/app/common/DTO/rates/rate.dto";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { ConvertCurrencyHelper } from "src/app/common/utils/convert-currency-helper.util";

@Component({
  selector: "app-balance-row",
  templateUrl: "./balance-row.component.html",
  styleUrls: ["./balance-row.component.css"],
  animations: [
    trigger("openClose", [
      state("true", style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
      state("false", style({ height: "0", visibility: "hidden" })),
      transition("false => true", animate("200ms")),
      transition("true => false", animate("200ms")),
    ]),
  ],
})
export class BalanceRowComponent {
  @Input() balanceItem: BalanceDto = new BalanceDto();
  @Input() rates: RateDto[] = [];
  @Input() isLoading: boolean = false;
  @Input() isShort: boolean = false;
  @Input() withSelect: boolean = false;
  @Input() selected: boolean = false;
  @Input() showRate: boolean = false;

  @Output() receiveClick = new EventEmitter<BalanceDto>();
  @Output() sendClick = new EventEmitter<BalanceDto>();
  @Output() swapClick = new EventEmitter<BalanceDto>();

  public CryptoSymbol = CryptoSymbol;
  public mobileVariantExpanded = false;
  public RouteConstants = RouteConstants;

  getCurrencyName(): string {
    switch (this.balanceItem.currency) {
      case CryptoSymbol.Usdt:
      case CryptoSymbol.PolygonUsdt:
      case CryptoSymbol.TonUsdt:
        return "USDT";
      case CryptoSymbol.Trx:
        return "TRX";
      case CryptoSymbol.Matic:
        return "MATIC";
      case CryptoSymbol.Ton:
        return "TON";
      case CryptoSymbol.Not:
        return "NOT";
      case CryptoSymbol.Bitcoin:
        return "BTC";
      case CryptoSymbol.AbstractUsdt:
        return "USDT";
      default:
        return "-";
    }
  }

  public getUzsValue(amount: number): number {
    if (!this.rates || this.rates.length === 0) {
      return 0;
    }
    return ConvertCurrencyHelper.convertToUzs(amount, this.balanceItem.currency, this.rates);
  }

  public getUsdValue(amount: number): number {
    if (!this.rates || this.rates.length === 0) {
      return 0;
    }
    return ConvertCurrencyHelper.convertToUsd(amount, this.balanceItem.currency, this.rates);
  }

  handleReceiveClick() {
    this.receiveClick.emit(this.balanceItem);
  }

  handleSendClick() {
    this.sendClick.emit(this.balanceItem);
  }

  handleSwapClick() {
    this.swapClick.emit(this.balanceItem);
  }

  toggleMobileView() {
    this.mobileVariantExpanded = !this.mobileVariantExpanded;
  }
}

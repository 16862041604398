<a
  [routerLink]="item.link.split('?')[0]"
  class="flex items-center rounded-[3rem] border border-transparent px-4 py-3 text-text-secondary transition-colors hover:border-gray-100 hover:text-text-primary dark:hover:border-slate-700 dark:hover:bg-slate-700 dark:hover:text-white"
  [ngClass]="{ 'bg-gray-100 !text-text-primary dark:bg-slate-700 dark:!text-white': isCurrent }"
  (click)="onItemClick($event)"
>
  <app-svg-icon [name]="item.icon" class="h-6 w-6 shrink-0 max-md:h-7 max-md:w-7" />
  <span [@buttonExpand]="isFullMenu" class="ml-4 overflow-hidden whitespace-nowrap text-base font-semibold">
    {{ item.title | translate }}
  </span>
</a>

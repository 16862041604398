<div *ngIf="!isLoading; else skeleton">
  <!-- Desktop -->
  <div
    class="grid grid-cols-wallet-balances-table items-center gap-x-1 border-t border-solid border-gray-100 px-6 py-3 text-base font-semibold dark:border-slate-700 dark:text-white max-md:grid-cols-wallet-balances-table-mobile"
  >
    <div class="flex items-center gap-x-4">
      <app-currency-icon [currency]="balanceItem.currency" size="sm" />
      <div class="space-y-2">
        <span class="block">{{ getCurrencyName() }}</span>
        <!-- <span class="block">{{ getUsdValue(1) | mfeCustom }} $</span> -->
      </div>
    </div>

    <span>
      {{ balanceItem.availableAmount | mfeCustom: { currency: balanceItem.currency } }}
    </span>

    <span class="max-md:hidden">
      $<app-fractional-number [value]="getUsdValue(balanceItem.availableAmount) | mfeCustom" />
    </span>

    <span class="max-md:hidden">{{
      getUzsValue(balanceItem.availableAmount) | mfeCustom: { currency: CryptoSymbol.Uzs }
    }}</span>

    <div class="grid grid-cols-2 items-center gap-x-2 max-md:ml-auto max-md:w-fit">
      <button class="btn-secondary btn-small max-md:rounded-full max-md:p-2" (click)="handleReceiveClick()">
        <span class="max-md:hidden">Получить</span>
        <app-svg-icon name="actions/plus" class="h-6 w-6 md:hidden" />
      </button>

      <button class="btn-secondary btn-small max-md:rounded-full max-md:p-2" (click)="handleSendClick()">
        <span class="max-md:hidden">Отправить</span>
        <app-svg-icon name="arrow-up" class="h-6 w-6 md:hidden" />
      </button>

      <!-- <button class="btn-secondary btn-small" (click)="handleSwapClick()">Обмен</button> -->
    </div>
  </div>

  <!-- Mobile -->
  <!-- <div
    class="px-2 py-3 text-black transition-all dark:text-white md:hidden"
    [ngClass]="{ 'rounded-2xl bg-blue-50 dark:bg-slate-800': mobileVariantExpanded }"
  >
    <div class="flex items-center">
      <app-currency-icon [currency]="balanceItem.currency" size="sm" />

      <div class="ml-3 flex grow justify-between">
        <div class="space-y-1">
          <span class="text-sm font-medium">{{ getCurrencyName() }}</span>
          <span class="block text-xs text-slate-400 dark:text-slate-200"
            >{{ getUsdValue(1) | mfeCustom }} $</span
          >
        </div>

        <div class="space-y-1 text-end">
          <span class="text-sm font-medium">
            {{ balanceItem.availableAmount | mfeCustom: { currency: balanceItem.currency } }}
          </span>
          <span class="block text-xs text-slate-400 dark:text-slate-200"
            >$ {{ getUsdValue(balanceItem.availableAmount) | mfeCustom }}</span
          >
        </div>
      </div>

      <button
        class="ml-1 mt-1 flex h-5 w-5 items-center justify-center self-start rounded-full bg-red-50 transition-all dark:bg-slate-400"
        [ngClass]="{ 'rotate-180 dark:bg-green-400': mobileVariantExpanded }"
        (click)="toggleMobileView()"
      >
        <app-svg-icon name="chevron" class="block h-3 w-3 text-slate-400 dark:text-white" />
      </button>
    </div>

    <div [@openClose]="mobileVariantExpanded" class="overflow-hidden">
      <hr class="my-3 border-gray-100 dark:border-slate-700" />
      <a
        [routerLink]="'/' + RouteConstants.depositary + '/' + RouteConstants.transactions"
        [queryParams]="{ type: balanceItem.currency }"
        class="ml-auto flex w-fit items-center gap-x-1 text-sm text-slate-400 dark:text-slate-200"
      >
        История транзакций
        <app-svg-icon name="history" class="block h-4 w-4 text-black dark:text-white" />
      </a>

      <div class="mt-3 grid grid-cols-2 items-start justify-between gap-x-10">
        <button
          class="flex flex-col items-center border-0 px-0 font-medium text-green-400"
          (click)="handleReceiveClick()"
        >
          Получить
          <app-svg-icon
            name="actions/receive"
            class="h-6 w-6 max-md:mt-1 max-md:rounded-full max-md:bg-green-400 max-md:p-1 max-md:text-white"
          />
        </button>
        <button
          class="flex flex-col items-center border-0 px-0 font-medium text-green-400"
          (click)="handleSendClick()"
        >
          Отправить
          <app-svg-icon
            name="actions/send"
            class="h-6 w-6 max-md:mt-1 max-md:rounded-full max-md:bg-green-400 max-md:p-1 max-md:text-white"
          />
        </button>
      </div>
    </div>
  </div> -->
</div>

<ng-template #skeleton>
  <div
    class="grid grid-cols-wallet-balances-table items-center gap-x-1 px-6 py-3 max-md:grid-cols-wallet-balances-table-mobile"
  >
    <div class="flex items-center gap-x-4">
      <app-skeleton class="h-10 w-10 shrink-0" />
      <app-skeleton class="h-5 w-20 max-md:w-fill" />
    </div>
    <app-skeleton class="h-5 w-18" />
    <app-skeleton class="h-5 w-18 max-md:!hidden" />
    <app-skeleton class="h-5 w-18 max-md:!hidden" />

    <div class="grid grid-cols-2 gap-x-2 max-md:hidden">
      <button class="btn-secondary btn-small" disabled>Получить</button>
      <button class="btn-secondary btn-small" disabled>Отправить</button>
    </div>
    <div class="flex w-fit items-center justify-end gap-x-2 md:hidden">
      <app-skeleton class="h-10 w-10" />
      <app-skeleton class="h-10 w-10" />
    </div>
  </div>
</ng-template>

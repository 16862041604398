import { TxDto } from "src/app/common/DTO/txs/tx.dto";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { CryptoToken } from "src/app/common/enums/crypto-token.enum";
import { TxStatus } from "src/app/common/enums/tx-status.enum";
import { TxType } from "src/app/common/enums/tx-type.enum";
import { Transaction } from "src/app/common/models/transaction";

export class TxParserUtil {
  public static mapToTransaction(tx: TxDto): Transaction {
    const mappedItem = new Transaction();
    mappedItem.token = CryptoToken.Usdt;
    mappedItem.amount = tx.amount.toString();
    mappedItem.to = tx.to;
    mappedItem.from = tx.from;
    mappedItem.createdAt = tx.createdAt;
    mappedItem.status = TxStatus.WaitingApprove;
    mappedItem.type = TxType.Out;
    mappedItem.id = tx.id;
    return mappedItem;
  }

  public static getTxDestination(type: TxType): string {
    return type == TxType.In ? "Transaction.Receiving" : "Transaction.Sending";
  }

  public static getTxStatus(status: TxStatus): string {
    switch (status) {
      case TxStatus.Approved:
        return "Transaction.Status_approved";
      case TxStatus.Canceled:
        return "Transaction.Status_canceled";
      case TxStatus.NotApproved:
        return "Transaction.Status_admin_cancelled";
      case TxStatus.OnConfirm:
        return "Transaction.Status_network_pending";
      case TxStatus.WaitingApprove:
        return "Transaction.Status_admin_pending";
      default:
        throw new Error(`Argument ${status} out of range`);
    }
  }

  public static getFormattedDate(date: Date): string {
    return date.toLocaleString("ru-RU").split(",")[0];
  }

  public static getCryptoSymbol(token: CryptoToken): CryptoSymbol {
    switch (token) {
      case CryptoToken.Usdt:
        return CryptoSymbol.Usdt;
      case CryptoToken.PolygonUsdt:
        return CryptoSymbol.PolygonUsdt;
      case CryptoToken.TonUsdt:
        return CryptoSymbol.TonUsdt;
      case CryptoToken.Not:
        return CryptoSymbol.Not;
      case CryptoToken.Bitcoin:
        return CryptoSymbol.Bitcoin;
      case CryptoToken.Trx:
        return CryptoSymbol.Trx;
      case CryptoToken.Matic:
        return CryptoSymbol.Matic;
      case CryptoToken.Ton:
        return CryptoSymbol.Ton;
      default:
        return CryptoSymbol.AbstractUsdt;
    }
  }
}

<div class="box p-5">
  <div class="border-b border-gray-100 pb-5">
    <h3 class="altitle">Функции</h3>
  </div>
  <div class="mt-5 space-y-2" [formGroup]="functionalityForm">
    <div class="flex items-center justify-between rounded-2xl bg-gray-25 px-4 py-3">
      <p class="text-sm font-medium dark:text-slate-200">{{ "Admin.Home.Buy" | translate }}</p>
      <app-switch [disabled]="!hasInteractionPermissions" formControlName="canBuy" />
    </div>
    <div class="flex items-center justify-between rounded-2xl bg-gray-25 px-4 py-3">
      <p class="text-sm font-medium dark:text-slate-200">{{ "Admin.Home.Sell" | translate }}</p>
      <app-switch [disabled]="!hasInteractionPermissions" formControlName="canSell" />
    </div>
    <div class="flex items-center justify-between rounded-2xl bg-gray-25 px-4 py-3">
      <p class="text-sm font-medium dark:text-slate-200">{{ "Admin.Home.Send" | translate }}</p>
      <app-switch [disabled]="!hasInteractionPermissions" formControlName="canSend" />
    </div>
  </div>
</div>

<app-base-auth-form>
  <section class="mx-auto max-w-[35.5rem] rounded-2xl border border-gray-100 p-8">
    <h2 class="title text-center">{{ "Login.Enter" | translate }}</h2>
    <form class="mt-6" [formGroup]="adminForm" (ngSubmit)="handleLogin()">
      <div class="flex flex-col space-y-4 rounded-2xl bg-gray-25 p-4">
        <app-input
          [title]="'Login.Login' | translate"
          type="text"
          id="login"
          formControlName="login"
          autocomplete="off"
        />
        <app-input
          [title]="'Login.Password' | translate"
          type="password"
          id="psw"
          formControlName="psw"
          [withPasswordToggle]="true"
        />
      </div>
      <button
        type="submit"
        class="btn-primary btn-medium mt-6 w-full"
        [disabled]="adminForm.invalid || isPending"
      >
        {{ "Login.Enter" | translate }}
      </button>

      <small class="text-sm text-invalid-color" *ngFor="let error of authErrors">{{ error }}</small>
    </form>
  </section>
</app-base-auth-form>

<section class="admin-body">
  <app-tabs
    [tabs]="tokenSwitchValues"
    (onSelect)="onSelectCurrency($event)"
    variant="panels"
    class="block overflow-x-auto overflow-y-hidden border-b border-gray-100 [&_button]:uppercase"
  />

  <div class="grid grid-cols-3 gap-5">
    <div>
      <app-control-panel-block [selectedCurrency]="selectedCurrency"></app-control-panel-block>
      <app-rate-block [selectedCurrency]="selectedCurrency" class="mt-5 block"></app-rate-block>
    </div>

    <app-commission-block [selectedCurrency]="selectedCurrency"></app-commission-block>

    <app-limits-block [selectedCurrency]="selectedCurrency"></app-limits-block>
  </div>
</section>

<section class="admin-body">
  <div class="flex items-center justify-between border-b border-gray-100">
    <app-tabs variant="panels" [tabs]="tabs" (onSelect)="onTabSelect($event)" />
    <button class="btn-outline btn-small" (click)="onClickAdd()" [disabled]="!hasInteractionPermissions">
      {{ "Common.Add" | translate }}
      <app-svg-icon name="plus" class="ml-3 w-6" />
    </button>
  </div>

  <!-- Admins -->
  <table class="adminTable" *ngIf="currentTab.id === 1">
    <thead>
      <tr>
        <th scope="col">{{ "Admin.Admins.Login" | translate }}</th>
        <th scope="col">{{ "Admin.Admins.Password" | translate }}</th>
        <th scope="col">{{ "Admin.Admins.Role" | translate }}</th>
        <th scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let admin of admins">
        <td>{{ admin.login }}</td>
        <td>********</td>
        <td>
          <app-dropdown
            *ngIf="canRoleBeChanged(admin); else roleRenderer"
            [items]="roleNames"
            (onSelect)="handleChangeRole(admin.id, $event)"
          >
            <button class="flex items-center gap-x-2" [disabled]="isPending">
              {{ admin.role }}
              <app-svg-icon name="chevron-down" class="block h-3 w-3" />
            </button>
          </app-dropdown>
          <ng-template #roleRenderer>
            {{ admin.role }}
          </ng-template>
        </td>
        <td class="flex justify-end">
          <button
            class="h-6 w-6 text-slate-400 hover:text-red-400 disabled:cursor-not-allowed disabled:opacity-50"
            title="Удалить админа"
            (click)="handleDeleteAdmin(admin.id)"
            [disabled]="!canDeleteAdmin(admin) || isPending"
          >
            <app-svg-icon name="trash-bin" />
          </button>
        </td>
      </tr>
    </tbody>
  </table>

  <!-- Roles -->
  <app-roles-block *ngIf="currentTab.id === 2" [roles]="roles" (onRolesChange)="onRolesChange()" />
</section>

<jw-modal [id]="ModalConstants.AdminAddAdmin" (onClose)="clearForms()">
  <form
    *ngIf="!showSuperConfirmation; else superConfirmation"
    class="flex flex-col space-y-6"
    [formGroup]="addAdminForm"
    (ngSubmit)="openConfirmation()"
  >
    <h4 class="half-title text-center">{{ "Admin.Admins.Add_admin" | translate }}</h4>
    <app-input
      type="text"
      id="newAdminName"
      [title]="'Имя, Фамилия'"
      placeholder="Степан"
      formControlName="name"
      wrapperClasses="[&>input]:bg-gray-25"
      [errorMsg]="formControlError('name')"
    />
    <app-input
      type="phone"
      id="newAdminSurname"
      [title]="'Номер телефона'"
      placeholder="11 222 33 44"
      mask="00 000 00 00"
      formControlName="phoneNumber"
      wrapperClasses="[&>input]:bg-gray-25"
      [errorMsg]="formControlError('phoneNumber')"
    />
    <div>
      <p class="mb-1 font-medium">Роль</p>
      <app-dropdown [items]="roleNames" (onSelect)="handleChangeRoleOnAdd($event)">
        <button
          type="button"
          class="flex w-full items-center justify-between truncate rounded-2xl border border-green-400 p-4 font-medium"
        >
          {{ selectedRoleNameOnAdd ?? "Выберите" }}
          <app-svg-icon name="chevron-down" class="block h-4 w-4" />
        </button>
      </app-dropdown>
    </div>

    <app-input
      [title]="'Admin.Admins.Login' | translate"
      type="text"
      placeholder="admin"
      formControlName="login"
      id="newAdminLogin"
      wrapperClasses="[&>input]:bg-gray-25"
      [errorMsg]="formControlError('login')"
    />
    <app-input
      [title]="'Admin.Admins.Password' | translate"
      type="password"
      placeholder="********"
      formControlName="psw"
      id="newAdminPsw"
      wrapperClasses="[&>input]:bg-gray-25"
      [errorMsg]="formControlError('psw')"
    />
    <button type="submit" class="btn-primary btn-medium" [disabled]="addAdminForm.invalid">
      {{ "Common.Add" | translate }}
    </button>
  </form>
</jw-modal>

<ng-template #superConfirmation>
  <div class="flex flex-col space-y-4">
    <h4 class="half-title text-center">{{ "Admin.Admins.Superadmin_confirm" | translate }}</h4>
    <app-input
      [title]="'Admin.Admins.Superadmin_password' | translate"
      type="password"
      placeholder="******"
      id="newAdminConfirm"
      wrapperClasses="[&>input]:bg-gray-25"
      [formControl]="adminPsw"
    />
    <button
      type="submit"
      class="btn-primary btn-medium"
      (click)="handleAddAdmin()"
      [disabled]="adminPsw.invalid || isPending"
    >
      {{ "Common.Approve" | translate }}
    </button>
    <small class="block text-sm text-invalid-color" *ngIf="addErrorMessage">
      {{ addErrorMessage | translate }}
    </small>
  </div>
</ng-template>

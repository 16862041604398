import { Component, Input, OnInit } from "@angular/core";
import { RateDto } from "src/app/common/DTO/rates/rate.dto";
import { WalletDto } from "src/app/common/DTO/wallets/wallet.dto";
import { Network } from "src/app/common/enums/network.enum";
import { Transaction } from "src/app/common/models/transaction";
import { BitcoinService } from "src/app/services/bitcoin.service";
import { PolygonService } from "src/app/services/polygon.service";
import { RatesService } from "src/app/services/rates.service";
import { TonService } from "src/app/services/ton.service";
import { TronService } from "src/app/services/tron.service";
import { WalletService } from "src/app/services/wallet.service";

@Component({
  selector: "app-latest-transactions",
  templateUrl: "./latest-transactions.component.html",
  styleUrls: ["./latest-transactions.component.css"],
})
export class LatestTransactionsComponent implements OnInit {
  @Input() txCount: number = 7;

  public wallets: WalletDto[] = [];
  public rates: RateDto[] = [];

  private trxTxs: Transaction[] = [];
  private usdtTxs: Transaction[] = [];
  private maticTxs: Transaction[] = [];
  private polygonUsdtTxs: Transaction[] = [];
  private tonTxs: Transaction[] = [];
  private tonUsdtTxs: Transaction[] = [];
  private notTxs: Transaction[] = [];
  private bitcoinTxs: Transaction[] = [];

  public isTxsLoading = true;

  constructor(
    private readonly _tronService: TronService,
    private readonly _polygonService: PolygonService,
    private readonly _tonService: TonService,
    private readonly _bitcoinService: BitcoinService,
    private readonly _walletService: WalletService,
    private readonly _ratesService: RatesService
  ) {}

  async ngOnInit(): Promise<void> {
    this.wallets = (await this._walletService.getMy()).params ?? [];
    this.rates = (await this._ratesService.getRates()).params ?? [];
    this.loadTxs();
  }

  public get txs() {
    const groupedTxs = [
      ...this.trxTxs,
      ...this.usdtTxs,
      ...this.maticTxs,
      ...this.polygonUsdtTxs,
      ...this.tonTxs,
      ...this.tonUsdtTxs,
      ...this.notTxs,
      ...this.bitcoinTxs,
    ];
    return groupedTxs.sort((a, b) => b.timestamp - a.timestamp).slice(0, this.txCount);
  }

  private async loadTxs() {
    this.isTxsLoading = true;

    const trxAddress = this.wallets.find(x => x.network === Network.Tron)?.address;
    const polygonAddress = this.wallets.find(x => x.network === Network.Polygon)?.address;
    const tonAddress = this.wallets.find(x => x.network === Network.Ton)?.address;
    const bitcoinAddress = this.wallets.find(x => x.network === Network.Bitcoin)?.address;
    if (!trxAddress || !polygonAddress || !tonAddress || !bitcoinAddress) {
      this.isTxsLoading = false;
      return;
    }
    const trxTxsRes = await this._tronService.getTrxTransactions(trxAddress, true);
    this.trxTxs = trxTxsRes.items.slice(0, this.txCount);

    const usdtTxsRes = await this._tronService.getUsdtTransactions(trxAddress, true);
    this.usdtTxs = usdtTxsRes.items.slice(0, this.txCount);

    const maticTxsRes = await this._polygonService.getMaticTransactions(polygonAddress, 1);
    this.maticTxs = maticTxsRes.slice(0, this.txCount);

    const polygonUsdtTxsRes = await this._polygonService.getUsdtTransactions(polygonAddress, 1);
    this.polygonUsdtTxs = polygonUsdtTxsRes.slice(0, this.txCount);

    const tonTxsRes = await this._tonService.getTonTransactions(tonAddress);
    this.tonTxs = tonTxsRes.items.slice(0, this.txCount);

    const tonUsdtTxsRes = await this._tonService.getUsdtTransactions(tonAddress);
    this.tonUsdtTxs = tonUsdtTxsRes.items.slice(0, this.txCount);

    const notTxsRes = await this._tonService.getNotTransactions(tonAddress);
    this.notTxs = notTxsRes.items.slice(0, this.txCount);

    const bitcoinTxsRes = await this._bitcoinService.getBitcoinTransactions(tonAddress);
    this.bitcoinTxs = bitcoinTxsRes.slice(0, this.txCount);

    this.isTxsLoading = false;
  }
}

import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { KycDto } from "src/app/common/DTO/kyc/kyc.dto";
import { UserDto } from "src/app/common/DTO/users/user.dto";
import { Constants } from "src/app/common/constants/constants";
import { EventTypeConstants } from "src/app/common/constants/event-type.constants";
import { MENU_ITEMS } from "src/app/common/constants/menu-items.constant";
import { RouteConstants } from "src/app/common/constants/route.constants";
import { UrnConstants } from "src/app/common/constants/urn.constants";
import { PlatformType } from "src/app/common/enums/platform-type.enum";
import { getRoleName } from "src/app/common/utils/role-name-helper.util";
import { TelegramMiniAppHelper } from "src/app/common/utils/telegram-mini-app-helper.util";
import { TimeHelperUtil } from "src/app/common/utils/time-helper.util";
import { UserSettingsModalComponent } from "src/app/components/user-settings-modal/user-settings-modal.component";
import { SignInModalComponent } from "src/app/pages/landing/modals/sign-in-modal/sign-in-modal.component";
import { SignUpModalComponent } from "src/app/pages/landing/modals/sign-up-modal/sign-up-modal.component";
import { AuthService } from "src/app/services/auth.service";
import { EnvService } from "src/app/services/env.service";
import { EventBusService } from "src/app/services/event-bus.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { ScreenSizeService } from "src/app/services/screen-size.service";
import { SessionService } from "src/app/services/session.service";
import { ThemeService } from "src/app/services/theme.service";
import { TonConnectService } from "src/app/services/ton-connect.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() public withAuthState = true;
  @Input() public transparent = false;
  @Input() public fullSize = true;

  public isAuth = false;
  public user = new UserDto();
  public platformTypes = PlatformType;
  public isMobileMenuOpen = false;
  public isTonWalletMenuOpen = false;
  public mobileMenuItems = MENU_ITEMS;
  public tonWalletAddress = "";
  public confirmationTime = 0;
  public RouteConstants = RouteConstants;
  public isTelegramMiniApp = TelegramMiniAppHelper.isMiniApp();
  public isMobile: boolean = false;
  public mode: "dark" | "light" = "light";

  private cleanUpFunctionsList: (VoidFunction | undefined)[] = [];

  constructor(
    private readonly _router: Router,
    private readonly _authService: AuthService,
    public readonly _localStorage: LocalStorageService,
    private readonly _envService: EnvService,
    private readonly _eventBusService: EventBusService,
    private readonly _modalService: NgbModal,
    private readonly _sessionService: SessionService,
    private readonly _tonConnectService: TonConnectService,
    private readonly _themeService: ThemeService,
    private readonly _screenSizeService: ScreenSizeService
  ) {
    this._eventBusService.handle(EventTypeConstants.ChangeAvatar, (data: string) => {
      this.user.avatar = data;
    });

    this._eventBusService.handle(EventTypeConstants.Logout, () => {
      this.isAuth = false;
      this.user = new UserDto();
    });

    this._eventBusService.handle(EventTypeConstants.UpdateVerificationStatus, (data: KycDto | null) => {
      this.user.kyc = data;
    });

    this.tonWalletAddress = _tonConnectService.tonConnectUi?.account?.address || "";

    this._themeService.theme$.subscribe(theme => {
      this.mode = theme;
    });

    this.isMobile = this._screenSizeService.isMobile;
    this._screenSizeService.isMobile$.subscribe(isMobile => {
      this.isMobile = isMobile;
    });
  }

  async ngOnInit() {
    const user = await this._localStorage.getUserData();
    const tokenLifetime = await this._localStorage.accessTokenLifetime();
    const refreshLifetime = await this._localStorage.refreshTokenLifetime();
    const isValidToken = TimeHelperUtil.checkLifetimeIsValid(tokenLifetime);
    const isValidRefresh = TimeHelperUtil.checkLifetimeIsValid(refreshLifetime);

    if (user != null && (isValidToken || isValidRefresh)) {
      this.user = user;
      this.isAuth = true;
    }

    const remaining = await this._sessionService.getRemainingTime();
    if (remaining > 0 || isValidRefresh) {
      this._sessionService.startTimer();
    }

    const tonStatusUnsubscribe = this._tonConnectService?.tonConnectUi?.onStatusChange?.(wallet => {
      this.tonWalletAddress = wallet?.account?.address || "";
      this.isTonWalletMenuOpen = false;
    });
    this.cleanUpFunctionsList.push(tonStatusUnsubscribe);
  }

  ngOnDestroy() {
    this.cleanUpFunctionsList.map(cleanUp => cleanUp?.());
  }

  public get profilePhoto() {
    if (this.user.avatar == null) {
      return Constants.ProfilePhotoBase;
    } else {
      return `${this._envService.serverUrl}${UrnConstants.StaticFolder}${this.user.avatar}`;
    }
  }

  public get isAdmin() {
    return this.user.role.isAdmin;
  }

  public get userRoleName() {
    return getRoleName(this.user.role.name);
  }

  public openSignInModal() {
    this._modalService.open(SignInModalComponent);
  }

  public openSignUpModal() {
    const modalRef = this._modalService.open(SignUpModalComponent);
    modalRef.componentInstance.variant = "sign-up";
  }

  public openSettingsModal() {
    const modalRef = this._modalService.open(UserSettingsModalComponent, {
      modalDialogClass: "modal-dialog_full-content",
    });
    modalRef.componentInstance.user = this.user;
  }

  public openMobileMenuModal(content: any) {
    this._modalService.open(content, {
      modalDialogClass: "modal-dialog_mobile-menu",
      windowClass: "!items-start",
    });
  }

  public handleTonWalletConnectOrDisconnectClick(disconnectWalletContent: any, modal: any) {
    if (this.tonWalletAddress) {
      this._modalService.open(disconnectWalletContent);
    } else {
      this.handleTonWalletConnect();
    }
    modal?.close?.();
  }

  private handleTonWalletConnect() {
    this._tonConnectService.openTelegramWallet();
  }

  public handleTonWalletDisconnect(modal: any) {
    this._tonConnectService.tonConnectUi?.disconnect?.();
    modal?.close?.();
  }

  public async exit(): Promise<void> {
    await this._authService.logout();
    this.isAuth = false;
    this.isMobileMenuOpen = false;

    await this._localStorage.clearTokens();
    await this._localStorage.removeUserData();

    this.user = new UserDto();

    if (this.isAdmin) {
      this._router.navigateByUrl(`${RouteConstants.admin}/${RouteConstants.login}`);
    } else {
      this._router.navigateByUrl("/");
    }
  }
}

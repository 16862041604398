import { Component, Input } from "@angular/core";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";

export type CurrencyIconName =
  | "usdt-tron"
  | "usdt-matic"
  | "tron"
  | "matic"
  | "usdt"
  | "ton"
  | "usdt-ton"
  | "not"
  | "bitcoin"
  | "uzs"
  | "usd"
  | "blank";

@Component({
  selector: "app-currency-icon",
  templateUrl: "./currency-icon.component.html",
  styleUrls: ["./currency-icon.component.css"],
})
export class CurrencyIconComponent {
  @Input() currency: CryptoSymbol | undefined = undefined;
  @Input() size?: "sm" | "md" | "xs" = "md";

  public get currencyIconName(): CurrencyIconName {
    if (this.currency) {
      switch (this.currency) {
        case CryptoSymbol.Uzs:
          return "uzs";
        case CryptoSymbol.Usdt:
          return "usdt-tron";
        case CryptoSymbol.Trx:
          return "tron";
        case CryptoSymbol.Matic:
          return "matic";
        case CryptoSymbol.PolygonUsdt:
          return "usdt-matic";
        case CryptoSymbol.Ton:
          return "ton";
        case CryptoSymbol.TonUsdt:
          return "usdt-ton";
        case CryptoSymbol.Not:
          return "not";
        case CryptoSymbol.Bitcoin:
          return "bitcoin";
        case CryptoSymbol.AbstractUsdt:
          return "usdt";
        default:
          return "blank";
      }
    } else {
      return "blank";
    }
  }
}

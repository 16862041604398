import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CryptoTxDto } from "src/app/common/DTO/crypto/crypto-tx.dto";
import { TxDto } from "src/app/common/DTO/txs/tx.dto";
import { AdminPermission } from "src/app/common/enums/admin-permission.enum";
import { BankingTransactionInternalStatus } from "src/app/common/enums/banking-transaction-internal-status.enum";
import { BankingTransactionType } from "src/app/common/enums/banking-transaction-type.enum";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { CryptoService } from "src/app/services/crypto.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { TxService } from "src/app/services/tx.service";

@Component({
  templateUrl: "./admin-transactions.component.html",
  styleUrls: ["./admin-transactions.component.css"],
})
export class AdminTransactionsComponent implements OnInit {
  public sendTxs: TxDto[] = [];
  public sendPage: number = 1;
  public sendTotalCount: number = 0;

  public cryptoTxs: CryptoTxDto[] = [];
  public cryptoPage: number = 1;
  public cryptoTotalCount: number = 0;

  public pageSize: number = 5;
  public isVerdictPending: boolean = false;
  public isCryptoVerdictPending: boolean = false;
  public hasInteractionPermissions: boolean = false;

  constructor(
    private readonly _localStorage: LocalStorageService,
    private readonly _txService: TxService,
    private readonly _cryptoService: CryptoService,
    private readonly _translateService: TranslateService
  ) {}

  async ngOnInit(): Promise<void> {
    this.hasInteractionPermissions = await this._localStorage.adminHasPermission(
      AdminPermission.TransactionInteraction
    );
    await this.requestSendTxs();
    await this.requestCryptoTxs();
  }

  public async requestSendTxs() {
    const res = await this._txService.getWaitingTxs(this.pageSize, this.sendPage);
    this.sendTxs = res.params!.items;
    this.sendTotalCount = res.params!.totalCount;
  }

  public async handleVerdictSendTx(txId: string, verdict: boolean) {
    this.isVerdictPending = true;

    try {
      await this._txService.verdictWaitingTx(txId, verdict);
    } catch (error) {
      return;
    } finally {
      this.isVerdictPending = false;
    }

    await this.requestSendTxs();
  }

  public async handleRevertWithdrawTx(txId: number) {
    await this._txService.revertWithdraw(txId);

    await this.requestCryptoTxs();
  }

  public async requestCryptoTxs() {
    const res = await this._cryptoService.getTransactions(this.cryptoPage, this.pageSize);
    if (res.params) {
      this.cryptoTxs = res.params.items;
      this.cryptoTotalCount = res.params.totalCount;
    }
  }

  public getCryptoTxType(type: BankingTransactionType) {
    // ?? TODO: check if it is correct
    if (type === BankingTransactionType.Deposit) {
      return this._translateService.instant("Crypto_transaction.Withdraw");
    } else if (type === BankingTransactionType.Withdraw) {
      return this._translateService.instant("Crypto_transaction.Deposit");
    } else {
      return "";
    }
  }

  public getCryptoCurrencyName(currency: CryptoSymbol) {
    if (currency === CryptoSymbol.Tiyins) {
      return "UZS";
    }

    switch (currency) {
      case CryptoSymbol.Uzs:
        return "UZS";
      case CryptoSymbol.Usdt:
        return "USDT";
      case CryptoSymbol.Trx:
        return "TRX";
      case CryptoSymbol.Matic:
        return "MATIC";
      case CryptoSymbol.PolygonUsdt:
        return "Polygon USDT";
      default:
        return "";
    }
  }

  public getCryptoCurrencyValue(amount: number, currency: CryptoSymbol) {
    if (currency === CryptoSymbol.Tiyins) {
      return amount / 100;
    } else {
      return amount;
    }
  }

  public getCryptoTxStatus(status: BankingTransactionInternalStatus) {
    switch (status) {
      case BankingTransactionInternalStatus.Pending:
        return this._translateService.instant("Crypto_transaction.Internal_pending");
      case BankingTransactionInternalStatus.Complete:
        return this._translateService.instant("Crypto_transaction.Internal_complete");
      case BankingTransactionInternalStatus.Error:
        return this._translateService.instant("Crypto_transaction.Internal_error");
      case BankingTransactionInternalStatus.WaitingForApproval:
        return this._translateService.instant("Crypto_transaction.Internal_waiting");
      case BankingTransactionInternalStatus.RejectedByAdmin:
        return this._translateService.instant("Crypto_transaction.Internal_rejected");
      default:
        return "";
    }
  }

  public isCryptoVerdictEnabled(status: BankingTransactionInternalStatus) {
    return status === BankingTransactionInternalStatus.WaitingForApproval;
  }

  public async handleVerdictCryptoTx(txId: number, verdict: boolean) {
    this.isCryptoVerdictPending = true;

    try {
      if (verdict) {
        await this._cryptoService.approveTx(txId);
      } else {
        await this._cryptoService.rejectTx(txId);
      }
    } catch (error) {
      return;
    } finally {
      this.isCryptoVerdictPending = false;
    }

    await this.requestCryptoTxs();
  }

  public getAddressOnExplorer(item: CryptoTxDto): string {
    return this.isPolygonNetwork(item)
      ? "https://polygonscan.com/address/" + item.walletPublicKey
      : "https://tronscan.io/#/address/" + item.walletPublicKey;
  }

  public getTransactionOnExplorer(item: CryptoTxDto): string {
    return this.isPolygonNetwork(item)
      ? "https://polygonscan.com/tx/" + item.cryptoTxHash
      : "https://tronscan.io/#/transaction/" + item.cryptoTxHash;
  }

  private isPolygonNetwork(item: CryptoTxDto): boolean {
    return (
      item.fromCurrency == CryptoSymbol.PolygonUsdt ||
      item.fromCurrency == CryptoSymbol.Matic ||
      item.toCurrency == CryptoSymbol.PolygonUsdt ||
      item.toCurrency == CryptoSymbol.Matic
    );
  }
}

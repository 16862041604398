import { Component, OnInit } from "@angular/core";
import { NgbCalendar, NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { AdminPermission } from "src/app/common/enums/admin-permission.enum";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { ReportsService } from "src/app/services/reports.service";

@Component({
  templateUrl: "./reports.component.html",
  styleUrls: ["./reports.component.css"],
})
export class ReportsComponent implements OnInit {
  hasInteractionPermissions: boolean = false;

  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null = null;
  toDate: NgbDate | null = null;
  startDate: NgbDate | null = null;
  maxDate: NgbDate | null = null;

  showDownloadButton = false;

  constructor(
    private readonly _reportsService: ReportsService,
    public readonly _translateService: TranslateService,
    private readonly _localStorage: LocalStorageService,
    private readonly _calendar: NgbCalendar
  ) {
    const oneMonthEarlier = this._calendar.getToday();
    oneMonthEarlier.month -= 1;
    oneMonthEarlier.day = 1;
    this.startDate = oneMonthEarlier;
    this.maxDate = this._calendar.getToday();
  }

  async ngOnInit(): Promise<void> {
    this.hasInteractionPermissions = await this._localStorage.adminHasPermission(
      AdminPermission.PlatformInteraction
    );
  }

  public handleExportClick() {
    if (this.fromDate && this.toDate) {
      this.showDownloadButton = true;
    }
  }

  public handleDownloadReports(report: "accounting" | "registered-users") {
    if (!this.fromDate || !this.toDate) {
      return;
    }

    const startDate = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day);
    const endDate = new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day);

    switch (report) {
      case "accounting":
        this._reportsService.getAccountingReport(startDate.toISOString(), endDate.toISOString());
        break;
      case "registered-users":
        this._reportsService.getRegisteredUsersReportForPeriodReport(
          startDate.toISOString(),
          endDate.toISOString()
        );
        break;
      default:
        break;
    }
  }

  public handleDownloadUsersData() {
    this._reportsService.getUsersPersonalDataReport();
  }

  public onDateSelection(date: NgbDate, instance?: any) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
      instance?.close();
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  public isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  public isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  public isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  public formatNgbDate(date?: NgbDate | null) {
    if (!date) {
      return "";
    }
    return `${String(date.day).padStart(2, "0")}.${String(date.month).padStart(2, "0")}.${date.year}`;
  }

  public get dateRange() {
    if (!this.fromDate || !this.toDate) {
      return "";
    }
    return `${this.formatNgbDate(this.fromDate)} - ${this.formatNgbDate(this.toDate)}`;
  }
}

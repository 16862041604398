<section class="admin-body">
  <h3 class="half-title">Отчёты</h3>

  <div class="box flex items-center justify-between">
    <h4 class="altitle">Создать отчёт</h4>
    <div ngbDropdown #myDrop="ngbDropdown" class="ml-auto mr-5">
      <button
        ngbDropdownToggle
        class="flex items-center rounded-2xl border border-slate-200 bg-white px-4 py-2"
      >
        <p [ngClass]="dateRange ? 'text-black' : 'text-slate-400'">
          {{ dateRange || "дд.мм.ггггг - дд.мм.ггггг" }}
        </p>
        <app-svg-icon name="calendar" class="ml-4 h-6 w-6 text-blue-950" />
      </button>
      <div ngbDropdownMenu>
        <ngb-datepicker
          #dp
          outsideDays="hidden"
          [displayMonths]="2"
          [dayTemplate]="t"
          (dateSelect)="onDateSelection($event, myDrop)"
          [startDate]="startDate!"
          [maxDate]="maxDate!"
          [weekdays]="false"
          class="ngb-datepicker"
        />
      </div>
    </div>
    <ng-template #t let-date let-focused="focused">
      <span
        class="custom-day"
        [class.focused]="focused"
        [class.range]="isRange(date)"
        [class.faded]="isHovered(date) || isInside(date)"
        (mouseenter)="hoveredDate = date"
        (mouseleave)="hoveredDate = null"
      >
        {{ date.day }}
      </span>
    </ng-template>

    <button
      class="btn-primary btn-small"
      (click)="handleExportClick()"
      [disabled]="!hasInteractionPermissions"
    >
      Выгрузить
    </button>
  </div>

  <div class="grid grid-cols-2 gap-x-5">
    <div class="box">
      <h4 class="altitle">{{ "Admin.Reports.Accounting_reports" | translate }}</h4>

      <div class="mt-5 border-t border-gray-100 pt-5">
        <div class="flex items-center gap-2 rounded-2xl bg-gray-25 px-4 py-2.5">
          <div class="text-sm font-medium">
            <span>{{ "Admin.Reports.Sell_buy_report" | translate }}:</span>
            <span class="block text-slate-400">{{ dateRange }}</span>
          </div>
          <button
            *ngIf="showDownloadButton"
            class="ml-auto w-8"
            (click)="handleDownloadReports('accounting')"
            [disabled]="!hasInteractionPermissions"
          >
            <app-svg-icon name="receive" />
          </button>
        </div>
      </div>
    </div>

    <div class="box">
      <h4 class="altitle">{{ "Admin.Reports.User_reports" | translate }}</h4>

      <div class="mt-5 space-y-5 border-t border-gray-100 pt-5">
        <div class="flex items-center gap-2 rounded-2xl bg-gray-25 px-4 py-2.5">
          <div class="text-sm font-medium">
            <span>{{ "Admin.Reports.Users_registration_report" | translate }}:</span>
            <span class="block text-slate-400">
              {{ dateRange }}
            </span>
          </div>
          <button
            *ngIf="showDownloadButton"
            class="ml-auto w-8"
            (click)="handleDownloadReports('registered-users')"
            [disabled]="!hasInteractionPermissions"
          >
            <app-svg-icon name="receive" />
          </button>
        </div>

        <div class="flex items-center gap-2 rounded-2xl bg-gray-25 px-4 py-2.5">
          <div class="text-sm font-medium">
            <span>{{ "Admin.Reports.Users_personal_data_report" | translate }}</span>
          </div>
          <button
            class="ml-auto w-8"
            (click)="handleDownloadUsersData()"
            [disabled]="!hasInteractionPermissions"
          >
            <app-svg-icon name="receive" />
          </button>
        </div>
      </div>
    </div>
  </div>
</section>

import { Component } from "@angular/core";
import { ChartConfiguration } from "chart.js";

@Component({
  selector: "app-available-assets",
  templateUrl: "./available-assets.component.html",
  styleUrls: ["./available-assets.component.css"],
})
export class AvailableAssetsComponent {
  public chartData: ChartConfiguration["data"] = {
    datasets: [
      {
        data: [65, 59, 80, 81, 56, 55, 40],
        borderColor: "#0BA859",
        backgroundColor: "#0BA859",
        pointBackgroundColor: "#0BA859",
        pointHoverBackgroundColor: "#0BA859",
        pointHoverBorderColor: "#767E7A",
      },
    ],
    labels: ["January", "February", "March", "April", "May", "June", "July"],
  };

  public chartOptions: ChartConfiguration["options"] = {
    elements: {
      line: {
        borderWidth: 1,
      },
      point: {
        radius: 0,
        hitRadius: 10,
      },
    },
    scales: {
      y: {
        ticks: {
          display: false,
        },
        grid: {
          display: false,
          drawBorder: false,
        },
      },
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          display: false,
        },
      },
    },
    plugins: {
      legend: { display: false },
    },
    responsive: true,
    aspectRatio: 3,
  };
}

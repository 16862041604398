<section class="rounded-2xl bg-gray-25 px-3 py-6 dark:bg-slate-800">
  <div class="flex items-center justify-between px-3">
    <h2 class="subtitle">
      <span class="max-md:hidden">Ваши транзакции</span>
      <span class="md:hidden">Транзакции</span>
    </h2>
    <a routerLink="transactions" class="flex items-center gap-x-2 font-bold text-green-400">
      Посмотреть все
      <app-svg-icon name="chevron" class="w-4 -rotate-90"></app-svg-icon>
    </a>
  </div>

  <div class="mt-6 space-y-1">
    <ng-container *ngIf="!isTxsLoading; else loader">
      <ng-container *ngIf="txs.length > 0; else noTxs">
        <app-transaction-list-item *ngFor="let tx of txs" [txDto]="tx" [rates]="rates" />
      </ng-container>
    </ng-container>

    <ng-template #loader>
      <app-transaction-list-item-skeleton />
    </ng-template>

    <ng-template #noTxs>
      <div class="text-center font-semibold text-text-secondary">Отсутствуют</div>
    </ng-template>
  </div>
</section>

import { Component, Input, OnInit } from "@angular/core";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { getCurrencyName } from "src/app/common/utils/currency-name-helper.util";
import { CalculatorService } from "src/app/services/calculator.service";

@Component({
  selector: "app-currency-calculator",
  templateUrl: "./currency-calculator.component.html",
  styleUrls: ["./currency-calculator.component.css"],
})
export class CurrencyCalculatorComponent implements OnInit {
  @Input() isOnLandingPage = false;

  type: "buy" | "sell" = "sell";
  CryptoSymbol = CryptoSymbol;

  private MAX_INPUT_VALUE = 100_000_000_000;

  constructor(private readonly _calculatorService: CalculatorService) {}

  async ngOnInit() {
    this._calculatorService.setFromCurrency(CryptoSymbol.Trx);
    this._calculatorService.setToCurrency(CryptoSymbol.Uzs);
    this._calculatorService.form.get("toAmount")?.disable?.();
    await this._calculatorService.init();
  }

  public get currencies() {
    return [
      CryptoSymbol.AbstractUsdt,
      CryptoSymbol.Trx,
      CryptoSymbol.Matic,
      CryptoSymbol.Ton,
      CryptoSymbol.Not,
      CryptoSymbol.Bitcoin,
    ];
  }

  public get calculatorForm() {
    return this._calculatorService.form;
  }

  public get fromCurrency() {
    return this._calculatorService.fromCurrency;
  }

  public get toCurrency() {
    return this._calculatorService.toCurrency;
  }

  public get fromAmount() {
    return this._calculatorService.fromAmount;
  }

  public get toAmount() {
    return this._calculatorService.toAmount;
  }

  public get pureUzsToAmount() {
    if (!this.fromAmount) {
      return 0;
    }
    return this._calculatorService.convertAmount(this.fromAmount, this.fromCurrency, CryptoSymbol.Uzs);
  }

  public get uzsServiceFee() {
    if (!this.pureUzsToAmount) {
      return 0;
    }
    return this.pureUzsToAmount * this.serviceFeeFactor;
  }

  private get serviceFeeFactor() {
    return this._calculatorService.serviceFeeFactor;
  }

  public get uzsNetworkFee() {
    if (!this.networkFee) {
      return 0;
    }
    return this._calculatorService.convertAmount(this.networkFee, this.fromCurrency, CryptoSymbol.Uzs);
  }

  private get networkFee() {
    return this._calculatorService.networkFee;
  }

  onChangeType(type: "buy" | "sell") {
    this.type = type;
    this.calculateOutputAmount();
  }

  onSelectInputCurrency(currency: CryptoSymbol) {
    this._calculatorService.setFromCurrency(currency);
    this.calculateOutputAmount();
  }

  onSelectOutputCurrency(currency: CryptoSymbol) {
    this._calculatorService.setToCurrency(currency);
    this.calculateOutputAmount();
  }

  calculateOutputAmount() {
    this._calculatorService.triggerCalculations(this.fromAmount);
  }

  handleOutputAmountChange(event: any) {
    let { value } = event.target;

    if (Number(value) > this.MAX_INPUT_VALUE) {
      value = value.toString().slice(0, this.MAX_INPUT_VALUE.toString().length);
      if (value !== event.target.value) {
        event.target.value = value;
        event.target.dispatchEvent(new Event("input"));
      }
    }

    this.calculateOutputAmount();
  }

  public get networkCurrencyName() {
    switch (this.fromCurrency) {
      case CryptoSymbol.Trx:
      case CryptoSymbol.Usdt:
        return "TRX";
      case CryptoSymbol.Matic:
      case CryptoSymbol.PolygonUsdt:
        return "MATIC";
      case CryptoSymbol.Ton:
      case CryptoSymbol.TonUsdt:
      case CryptoSymbol.Not:
        return "TON";
      case CryptoSymbol.Bitcoin:
        return "BTC";
      default:
        return "";
    }
  }

  public getCurrencyName(currency: CryptoSymbol) {
    return getCurrencyName(currency);
  }
}

<table class="adminTable">
  <thead>
    <tr>
      <th scope="col">Название</th>
      <th scope="col" *ngFor="let permission of permissionsList">{{ permission.name }}</th>
      <th scope="col"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let role of roles">
      <td>{{ role.name }}</td>
      <td *ngFor="let permission of permissionsList">
        <app-switch
          [ngModel]="getSwitchIsChecked(role.id, permission.value)"
          (ngModelChange)="handleSwitchChange($event, role.id, permission.value)"
          [disabled]="!canPermissionBeChanged(role, permission.value)"
        />
      </td>
      <td class="flex justify-end">
        <button
          class="h-6 w-6 text-slate-400 hover:text-red-400 disabled:cursor-not-allowed disabled:opacity-50"
          title="Удалить роль"
          (click)="handleDeleteRole(role.id)"
          [disabled]="!hasInteractionPermissions"
        >
          <app-svg-icon name="trash-bin" />
        </button>
      </td>
    </tr>
  </tbody>
</table>

<jw-modal [id]="ModalConstants.AdminAddRole" (onClose)="clearForms()">
  <form class="flex flex-col space-y-4" [formGroup]="addRoleForm" (ngSubmit)="onSubmitAddRole()">
    <h4 class="half-title text-center">Добавить роль</h4>
    <app-input
      type="text"
      [title]="'Название'"
      placeholder="Админ"
      id="addRoleName"
      formControlName="name"
      wrapperClasses="[&>input]:bg-gray-25"
    />
    <div class="flex items-center justify-between font-medium" *ngFor="let permission of addPermissionsList">
      {{ permission.name }}
      <app-switch
        [ngModel]="getSwitchOnAddIsChecked(permission.value)"
        (ngModelChange)="onChangeSwitchOnAdd(permission.value)"
        [ngModelOptions]="{ standalone: true }"
      />
    </div>
    <button type="submit" class="btn-primary btn-medium" [disabled]="addRoleForm.invalid || isPending">
      {{ "Common.Add" | translate }}
    </button>
  </form>
</jw-modal>

<div *ngIf="internalError" class="bg-invalid-color text-center">
  <div class="text-text-secondary">
    {{ "Common.Unknown_error" | translate }}
  </div>
</div>

<div
  class="max-md:my-2 max-md:rounded-2xl max-md:bg-gray-25 max-md:p-4 max-md:dark:bg-slate-800 md:absolute md:top-8"
>
  <h1 class="title">Страница активов</h1>
</div>

<app-balance-chart [totalUzsBalance]="totalUzsBalance" />

<!-- <div
  class="flex items-center justify-between rounded-2xl bg-gray-25 px-9 py-6 dark:border-slate-800 dark:bg-slate-800 max-md:flex-col max-md:items-start max-md:p-4"
>
  <div class="space-y-2 text-slate-400">
    <h2 class="text-lg dark:text-slate-200 max-md:text-sm">Баланс</h2>
    <span class="block text-3xl font-semibold text-black dark:text-white max-md:text-2xl">
      {{ totalUzsBalance | mfeCustom: { currency: CryptoSymbol.Uzs } }} UZS
    </span>
    <span class="block max-md:text-sm">Учитываем только UZS и USD</span>
  </div>

  <div
    class="grid grid-cols-3 gap-x-4 dark:border-slate-400 max-md:mt-4 max-md:w-full max-md:justify-between max-md:gap-x-10 max-md:border-t max-md:border-gray-100 max-md:pt-2"
  >
    <button
      class="btn-outline btn-medium gap-x-3 max-md:flex-col max-md:border-none max-md:p-0 max-md:font-medium"
      (click)="openReceiveModal()"
    >
      <span>Получить</span>
      <app-svg-icon
        name="actions/receive"
        class="h-6 w-6 max-md:mt-1 max-md:rounded-full max-md:bg-green-400 max-md:p-1 max-md:text-white"
      />
    </button>

    <button
      class="btn-outline btn-medium gap-x-3 max-md:flex-col max-md:border-none max-md:p-0 max-md:font-medium"
      (click)="openSendModal()"
    >
      <span>Отправить</span>
      <app-svg-icon
        name="actions/send"
        class="h-6 w-6 max-md:mt-1 max-md:rounded-full max-md:bg-green-400 max-md:p-1 max-md:text-white"
      />
    </button>

    <button
      class="btn-outline btn-medium gap-x-3 max-md:flex-col max-md:border-none max-md:p-0 max-md:font-medium"
      (click)="openSwapModal()"
    >
      <span>Обмен</span>
      <app-svg-icon
        name="actions/swap"
        class="h-6 w-6 max-md:mt-1 max-md:rounded-full max-md:bg-green-400 max-md:p-1 max-md:text-white"
      />
    </button>
  </div>
</div> -->

<section class="mt-2 rounded-2xl bg-gray-25 pb-6 dark:bg-slate-800 max-md:pb-4">
  <div
    class="flex items-center justify-between px-6 py-7 max-md:flex-col max-md:items-start max-md:gap-y-7 max-md:p-6"
  >
    <h2 class="subtitle">Мои активы</h2>
    <div class="flex items-center gap-x-4 max-md:mx-auto max-md:grid max-md:grid-cols-2">
      <button class="btn-primary btn-medium" (click)="openSwapModal()">Обменять</button>
      <button class="btn-primary btn-medium" (click)="openSwapModal()">Добавить актив</button>
    </div>
  </div>

  <div>
    <div
      class="grid grid-cols-wallet-balances-table items-center gap-x-1 px-6 py-3 text-xs font-medium text-text-secondary max-md:grid-cols-wallet-balances-table-mobile"
    >
      <span>Название</span>
      <span>Всего</span>
      <span class="max-md:hidden">В USDT</span>
      <span class="max-md:hidden">В UZS</span>
      <span></span>
    </div>

    <div>
      <ng-container *ngIf="balances.length > 0; else balancesSkeleton">
        <ng-container *ngFor="let balance of balances">
          <app-balance-row
            [balanceItem]="balance"
            [rates]="rates"
            (receiveClick)="openReceiveModal(balance)"
            (sendClick)="openSendModal(balance)"
            (swapClick)="openSwapModal(balance)"
          />
        </ng-container>
      </ng-container>

      <ng-template #balancesSkeleton>
        <app-balance-row [isLoading]="true" />
        <app-balance-row [isLoading]="true" />
        <app-balance-row [isLoading]="true" />
        <app-balance-row [isLoading]="true" />
        <app-balance-row [isLoading]="true" />
        <app-balance-row [isLoading]="true" />
      </ng-template>
    </div>
  </div>
</section>

<div class="mt-2 grid grid-cols-2 gap-2 max-md:grid-cols-1">
  <app-available-assets></app-available-assets>
  <app-latest-transactions [txCount]="7"></app-latest-transactions>
</div>

<div class="dropdown" ngbDropdown container="body" display="dynamic" placement="bottom-end top-end">
  <div id="custom-dropdown" ngbDropdownToggle>
    <ng-content></ng-content>
  </div>

  <ul
    *ngIf="!disabled"
    aria-labelledby="custom-dropdown"
    [ngClass]="{ '!w-full': isFullWidthList }"
    ngbDropdownMenu
  >
    <li *ngFor="let item of items" (click)="pickItem(item)" ngbDropdownItem>
      <span>{{ getNetworkName(item) }}</span>
    </li>
  </ul>
</div>

import { Component, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { RateDto } from "src/app/common/DTO/rates/rate.dto";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { CryptoToken } from "src/app/common/enums/crypto-token.enum";
import { TxStatus } from "src/app/common/enums/tx-status.enum";
import { TxType } from "src/app/common/enums/tx-type.enum";
import { Transaction } from "src/app/common/models/transaction";
import { MfeCustomPipe } from "src/app/common/pipes/mfe-custom.pipe";
import { ConvertCurrencyHelper } from "src/app/common/utils/convert-currency-helper.util";
import { getTransactionLink } from "src/app/common/utils/transaction-link-helper.util";
import { TxParserUtil } from "src/app/common/utils/tx-parser.util";
import { getWalletLink } from "src/app/common/utils/wallet-link-helper.util";

@Component({
  selector: "app-transaction-list-item",
  templateUrl: "./transaction-list-item.component.html",
  styleUrls: ["./transaction-list-item.component.css"],
})
export class TransactionListItemComponent {
  @Input() txDto = new Transaction();
  @Input() fullSize = false;
  @Input() rates: RateDto[] = [];

  public TxType = TxType;

  constructor(private readonly _translateService: TranslateService) {}

  public get balanceChange(): string {
    const amount = new MfeCustomPipe().transform(this.txDto.amount, {
      currency: this.txDto.token === CryptoToken.Bitcoin ? CryptoSymbol.Bitcoin : undefined,
    });

    return this.formatAmount(amount);
  }

  public get usdBalanceChange(): string {
    if (this.rates.length === 0) return "0";

    const amount = this.txDto.amount;
    const converted = ConvertCurrencyHelper.convertToUsd(
      +amount,
      TxParserUtil.getCryptoSymbol(this.txDto.token),
      this.rates
    );

    return this.formatAmount(converted, "$");
  }

  public get tokenName(): string {
    switch (this.txDto.token) {
      case CryptoToken.Usdt:
      case CryptoToken.PolygonUsdt:
      case CryptoToken.TonUsdt:
        return "USDT";
      case CryptoToken.Trx:
        return "TRX";
      case CryptoToken.Matic:
        return "MATIC";
      case CryptoToken.Ton:
        return "TON";
      case CryptoToken.Not:
        return "NOT";
      case CryptoToken.Bitcoin:
        return "BTC";
      default:
        return "-";
    }
  }

  public get txDestination(): string {
    if (!this.txDto.type) {
      return "Транзакция";
    }

    const destinationTag = TxParserUtil.getTxDestination(this.txDto.type);
    return this._translateService.instant(destinationTag);
  }

  public get txStatus(): string {
    const statusTag = TxParserUtil.getTxStatus(this.txDto.status);
    return this._translateService.instant(statusTag);
  }

  public get txDate(): string {
    return this.txDto.createdAt.toString();
  }

  public get iconName(): CryptoSymbol {
    switch (this.txDto.token) {
      case CryptoToken.Usdt:
        return CryptoSymbol.Usdt;
      case CryptoToken.Trx:
        return CryptoSymbol.Trx;
      case CryptoToken.PolygonUsdt:
        return CryptoSymbol.PolygonUsdt;
      case CryptoToken.Matic:
        return CryptoSymbol.Matic;
      case CryptoToken.Ton:
        return CryptoSymbol.Ton;
      case CryptoToken.TonUsdt:
        return CryptoSymbol.TonUsdt;
      case CryptoToken.Not:
        return CryptoSymbol.Not;
      case CryptoToken.Bitcoin:
        return CryptoSymbol.Bitcoin;
      default:
        return CryptoSymbol.Usdt;
    }
  }

  public get statusColor(): string {
    if (this.txDto.status === TxStatus.Approved) {
      return "text-green-400";
    } else if (this.txDto.status === TxStatus.OnConfirm || this.txDto.status === TxStatus.WaitingApprove) {
      return "text-waiting";
    } else {
      return "text-red-400";
    }
  }

  public returnWalletLink(address: string): string {
    return getWalletLink(this.txDto.network, address);
  }

  public get txLink(): string {
    return getTransactionLink(this.txDto.network, this.txDto.hash);
  }

  private formatAmount(amount: string | number, prefix: string = ""): string {
    if (amount === "0") return "0";

    const formattedAmount = `${prefix}${amount}`;
    return this.txDto.type === TxType.In
      ? `+${formattedAmount}`
      : this.txDto.type === TxType.Out
        ? `-${formattedAmount}`
        : formattedAmount;
  }
}

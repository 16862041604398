<div class="box p-5">
  <div class="flex items-center justify-between border-b border-gray-100 pb-5">
    <h3 class="altitle">{{ "Admin.Home.Limit" | translate }}</h3>
    <button
      *ngIf="hasInteractionPermissions"
      class="ml-auto w-5 text-slate-400 hover:text-black"
      (click)="handleOpenModal(selectedCurrency, editLimitsModal)"
    >
      <app-svg-icon name="edit" />
    </button>
  </div>

  <div class="mt-5 space-y-4">
    <p class="limit-row">
      {{ "Admin.Home.Day_limit" | translate }}:
      <span>{{
        getLimits(selectedCurrency)?.maxWithdrawalPerDay | mfeCustom: { currency: selectedCurrency }
      }}</span>
    </p>
    <p class="limit-row">
      {{ "Admin.Home.Operation_limit" | translate }}:
      <span>{{
        getLimits(selectedCurrency)?.maxWithdrawalPerTx | mfeCustom: { currency: selectedCurrency }
      }}</span>
    </p>
    <p class="limit-row">
      {{ "Admin.Home.Min_withdrawal_limit" | translate }}:
      <span>{{
        getLimits(selectedCurrency)?.minWithdrawalPerTx | mfeCustom: { currency: selectedCurrency }
      }}</span>
    </p>
    <p class="limit-row">
      {{ "Admin.Home.With_verification_limit" | translate }}:
      <span>{{
        getLimits(selectedCurrency)?.maxTxAmountWithoutConfirm | mfeCustom: { currency: selectedCurrency }
      }}</span>
    </p>
    <p class="limit-row">
      {{ "Admin.Home.Max_buy_limit" | translate }}:
      <span>{{ getLimits(selectedCurrency)?.maxBuyAmount | mfeCustom: { currency: selectedCurrency } }}</span>
    </p>
    <p class="limit-row">
      {{ "Admin.Home.Max_sell_limit" | translate }}:
      <span>{{
        getLimits(selectedCurrency)?.maxSellAmount | mfeCustom: { currency: selectedCurrency }
      }}</span>
    </p>
    <p class="limit-row">
      {{ "Admin.Home.Min_sell_limit" | translate }}:
      <span>{{
        getLimits(selectedCurrency)?.minSellAmount | mfeCustom: { currency: selectedCurrency }
      }}</span>
    </p>
    <p class="limit-row">
      {{ "Admin.Home.Min_buy_limit" | translate }}:
      <span>{{ getLimits(selectedCurrency)?.minBuyAmount | mfeCustom: { currency: selectedCurrency } }}</span>
    </p>
    <p class="limit-row">
      {{ "Admin.Home.Without_confirm_limit" | translate }}:
      <span>{{
        getLimits(selectedCurrency)?.maxAcquiringAmountWithoutConfirm
          | mfeCustom: { currency: selectedCurrency }
      }}</span>
    </p>
  </div>
</div>

<ng-template let-modal #editLimitsModal>
  <form [formGroup]="limitsForm" (ngSubmit)="handleSaveLimit()" class="space-y-8 px-9 py-8">
    <h4 class="text-center text-2xl font-semibold">
      {{ "Admin.Home.Limit" | translate }} {{ getCurrencyName(limitsForm.value["currency"]) }}
    </h4>
    <div class="grid max-w-[36.375rem] grid-cols-2 gap-6">
      <app-input
        [title]="'Admin.Home.Day_limit' | translate"
        type="number"
        placeholder="100"
        formControlName="maxWithdrawalPerDay"
        appOnlyNumbers
        [errorMsg]="getFieldError('maxWithdrawalPerDay')"
      />
      <app-input
        [title]="'Admin.Home.Operation_limit' | translate"
        type="number"
        placeholder="100"
        formControlName="maxWithdrawalPerTx"
        appOnlyNumbers
        [errorMsg]="getFieldError('maxWithdrawalPerTx')"
      />
      <app-input
        [title]="'Admin.Home.Min_withdrawal_limit' | translate"
        type="number"
        placeholder="100"
        formControlName="minWithdrawalPerTx"
        appOnlyNumbers
        [errorMsg]="getFieldError('minWithdrawalPerTx')"
      />
      <app-input
        [title]="'Admin.Home.With_verification_limit' | translate"
        type="number"
        placeholder="100"
        formControlName="maxTxAmountWithoutConfirm"
        appOnlyNumbers
        [errorMsg]="getFieldError('maxTxAmountWithoutConfirm')"
      />
      <app-input
        [title]="'Admin.Home.Max_buy_limit' | translate"
        type="number"
        placeholder="100"
        formControlName="maxBuyAmount"
        appOnlyNumbers
        [errorMsg]="getFieldError('maxBuyAmount')"
      />
      <app-input
        [title]="'Admin.Home.Min_buy_limit' | translate"
        type="number"
        placeholder="100"
        formControlName="minBuyAmount"
        appOnlyNumbers
        [errorMsg]="getFieldError('minBuyAmount')"
      />
      <app-input
        [title]="'Admin.Home.Max_sell_limit' | translate"
        type="number"
        placeholder="100"
        formControlName="maxSellAmount"
        appOnlyNumbers
        [errorMsg]="getFieldError('maxSellAmount')"
      />
      <app-input
        [title]="'Admin.Home.Min_sell_limit' | translate"
        type="number"
        placeholder="100"
        formControlName="minSellAmount"
        appOnlyNumbers
        [errorMsg]="getFieldError('minSellAmount')"
      />
      <app-input
        [title]="'Admin.Home.Without_confirm_limit' | translate"
        type="number"
        placeholder="100"
        formControlName="maxAcquiringAmountWithoutConfirm"
        appOnlyNumbers
        [errorMsg]="getFieldError('maxAcquiringAmountWithoutConfirm')"
      />
    </div>

    <button
      type="submit"
      class="btn-primary btn-medium mx-auto w-[28rem]"
      [disabled]="limitsForm.invalid || isPending"
    >
      {{ "Admin.Home.Change" | translate }}
    </button>
  </form>
</ng-template>

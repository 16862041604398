<div
  class="box p-5"
  *ngIf="
    selectedCurrency != symbols.Usdt &&
    selectedCurrency != symbols.PolygonUsdt &&
    selectedCurrency !== symbols.TonUsdt
  "
>
  <div class="border-b border-gray-100 pb-5">
    <h3 class="altitle">{{ "Admin.Home.Rate" | translate }} {{ currencyName }}</h3>
  </div>

  <div class="mt-5 flex items-center gap-x-2 text-sm font-medium">
    <span class="text-sm font-bold text-slate-400">1 {{ currencyName }}:</span>
    <span>{{ rate?.usd | mfeCustom }} USD</span>
  </div>
</div>

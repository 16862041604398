import { Component } from "@angular/core";
import { Constants } from "src/app/common/constants/constants";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { getCurrencyName } from "src/app/common/utils/currency-name-helper.util";

@Component({
  templateUrl: "./admin-home.component.html",
  styleUrls: ["./admin-home.component.css"],
})
export class AdminHomeComponent {
  public selectedCurrency: CryptoSymbol = CryptoSymbol.Trx;

  constructor() {}

  public get tokenSwitchValues() {
    return Array.from(Constants.TokenSwitchValues.values()).slice(0, -2);
  }

  public onSelectCurrency(tabNumber: number) {
    const currency = Array.from(Constants.TokenSwitchValues.keys())[tabNumber];
    this.selectedCurrency = currency as unknown as CryptoSymbol;
  }

  public getCurrencyName(currency: CryptoSymbol): string {
    return getCurrencyName(currency);
  }
}

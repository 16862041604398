import { AfterViewInit, Component, Input, ViewChild } from "@angular/core";
import { ChartConfiguration } from "chart.js";
import { BaseChartDirective } from "ng2-charts";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { ScreenSizeService } from "src/app/services/screen-size.service";

@Component({
  selector: "app-balance-chart",
  templateUrl: "./balance-chart.component.html",
  styleUrls: ["./balance-chart.component.css"],
})
export class BalanceChartComponent implements AfterViewInit {
  @Input() totalUzsBalance: number = 0;

  public CryptoSymbol = CryptoSymbol;

  public chartData: ChartConfiguration["data"] = {
    datasets: [
      {
        data: [65, 59, 80, 81, 56, 55, 40],
        borderColor: "#0BA859",
        pointBackgroundColor: "#0BA859",
        pointBorderColor: "#f4f4f4",
        pointHoverBackgroundColor: "#0BA859",
        pointHoverBorderColor: "#767E7A",
        fill: "origin",
      },
    ],
    labels: ["January", "February", "March", "April", "May", "June", "July"],
  };

  public chartOptions: ChartConfiguration["options"] = {
    elements: {
      line: {
        tension: 0.4,
        borderWidth: 1,
      },
      point: {
        hitRadius: 10,
      },
    },
    scales: {
      y: {
        ticks: {
          display: false,
        },
        grid: {
          display: false,
          drawBorder: false,
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 12,
          },
          color: "#767E7A",
        },
      },
    },
    plugins: {
      legend: { display: false },
    },
    responsive: true,
    aspectRatio: 4,
  };

  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

  constructor(private _screenSizeService: ScreenSizeService) {
    this._screenSizeService.isMobile$.subscribe(isMobile => {
      if (isMobile) {
        this.chartOptions!.aspectRatio = 2;
      } else {
        this.chartOptions!.aspectRatio = 4;
      }
      this.chart?.render();
    });
  }

  ngAfterViewInit(): void {
    if (this.chart) {
      const grad = this.createChartGradient(this.chart);
      this.chartData.datasets[0].backgroundColor = grad;
      this.chart.render();
    }
  }

  private createChartGradient(chart: BaseChartDirective) {
    const ctx = chart?.chart?.ctx;
    const gradient = ctx?.createLinearGradient(0, 0, 0, 200);
    gradient?.addColorStop(0, "rgba(11, 168, 89, 0.2)");
    gradient?.addColorStop(1, "rgba(11, 168, 89, 0.02)");
    return gradient;
  }
}

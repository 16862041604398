import { Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { Constants } from "src/app/common/constants/constants";
import { LanguageConstants } from "src/app/common/constants/language.constants";
import { ValidateConstants } from "src/app/common/constants/validate.constants";
import { ApprovePhoneDto } from "src/app/common/DTO/auth/approve-phone.dto";
import { SendConfirmCodeDto } from "src/app/common/DTO/auth/send-confirm-code.dto";
import { SignupDto } from "src/app/common/DTO/auth/signup.dto";
import { AuthErrorCode } from "src/app/common/enums/auth-error-code.enum";
import { TimeHelperUtil } from "src/app/common/utils/time-helper.util";
import { lengthEqualValidator } from "src/app/common/validators/length-equal.validator";
import { numericStrValidator } from "src/app/common/validators/numeric-str.validator";
import { validCodeRangeValidator } from "src/app/common/validators/valid-code-range.validator";
import { validOtpValidator } from "src/app/common/validators/valid-otp.validator";
import { AuthService } from "src/app/services/auth.service";
import { ToastService } from "src/app/services/toast.service";
import { SignInModalComponent } from "../sign-in-modal/sign-in-modal.component";

@Component({
  selector: "app-sign-up-modal",
  templateUrl: "./sign-up-modal.component.html",
  styleUrls: ["./sign-up-modal.component.css"],
})
export class SignUpModalComponent {
  step: "phone" | "password" | "otp" = "phone";
  hideOtherVariants = false;
  businessError: string | null = null;
  isPending: boolean = false;
  otpTimer: number = 0;
  validateConstants = ValidateConstants;
  otpInputCount: number = 0;

  phoneForm: FormGroup;
  // passwordForm: FormGroup;
  otpForm: FormGroup;

  private readonly phoneCode = Constants.UzsPhoneCode;

  constructor(
    private readonly _activeModal: NgbActiveModal,
    private readonly _translateService: TranslateService,
    private readonly _authService: AuthService,
    private readonly _toastService: ToastService,
    private readonly _modalService: NgbModal
  ) {
    this.phoneForm = new FormGroup({
      phoneNumber: new FormControl(null, [
        Validators.required,
        numericStrValidator(),
        lengthEqualValidator(ValidateConstants.PhoneLengthWithoutCode),
      ]),
      psw: new FormControl(null, [
        Validators.required,
        Validators.minLength(ValidateConstants.MinPswLength),
        Validators.maxLength(ValidateConstants.MaxPswLength),
        Validators.pattern(ValidateConstants.PswPattern),
      ]),
      checkDepositary: new FormControl(null, [Validators.requiredTrue]),
      // checkShop: new FormControl(null, [Validators.requiredTrue]),
    });

    // this.passwordForm = new FormGroup(
    //   {
    //     psw: new FormControl(null, [
    //       Validators.required,
    //       Validators.minLength(ValidateConstants.MinPswLength),
    //       Validators.maxLength(ValidateConstants.MaxPswLength),
    //       Validators.pattern(ValidateConstants.PswPattern),
    //     ]),
    //     pswRepeat: new FormControl(null, [Validators.required]),
    //   },
    //   { validators: matchValidator("psw", "pswRepeat") }
    // );

    this.otpForm = new FormGroup({
      code: new FormControl(null, [
        Validators.required,
        numericStrValidator(),
        validCodeRangeValidator(),
        validOtpValidator(),
      ]),
    });
  }

  public get language(): LanguageConstants {
    return this._translateService.currentLang as LanguageConstants;
  }

  public get phoneNumber() {
    return this.phoneForm.get("phoneNumber")?.value;
  }

  public get phoneNumberError() {
    const control = this.phoneForm.get("phoneNumber");

    if (!control?.touched) {
      return null;
    }
    if (control?.hasError("required")) {
      return this._translateService.instant("Common.Field_not_filled");
    }
    if (control?.hasError("numericStr")) {
      return this._translateService.instant("Login.Phone_numbers_error");
    }
    if (control?.hasError("lengthEqual")) {
      return this._translateService.instant("Login.Phone_length_error");
    }

    return null;
  }

  public get codeError() {
    const control = this.otpForm.get("code");

    if (!control?.touched) {
      return null;
    }
    if (control?.hasError("required")) {
      return this._translateService.instant("Common.Field_not_filled");
    }
    if (control?.hasError("numericStr")) {
      return this._translateService.instant("Common.Field_not_correct");
    }
    if (control?.hasError("validCodeRange")) {
      return this._translateService.instant("Common.Field_not_correct");
    }
    if (control?.hasError("validOtp")) {
      return this._translateService.instant("Common.Field_not_correct");
    }

    return null;
  }

  onClose() {
    this._activeModal.close();
  }

  onBack(): void {
    if (this.step === "password") {
      this.step = "phone";
      this.hideOtherVariants = false;
    } else if (this.step === "otp") {
      this.step = "password";
    }
    this.businessError = null;
  }

  captchaResolved(captchaResponse: string) {
    if (captchaResponse && captchaResponse != "") {
      this.otpInputCount = 0;
    }
  }

  async submitPhone() {
    if (this.phoneForm.invalid) {
      return;
    }

    const phoneNumber = this.phoneCode + this.phoneForm.get("phoneNumber")?.value;
    const res = await this._authService.checkPhoneIsExists(phoneNumber);

    if (res.withError) {
      this.businessError = this._translateService.instant("Common.Unknown_error");
      return;
    } else {
      if (res.params?.isExists) {
        this.businessError = this._translateService.instant("Login.Phone_already_in_use");
        return;
      }
    }

    this.step = "password";
    this.businessError = null;
    this.hideOtherVariants = true;
  }

  async submitPassword() {
    if (this.phoneForm.invalid) {
      return;
    }

    this.isPending = true;
    const dto: SignupDto = {
      phoneNumber: this.phoneCode + this.phoneForm.get("phoneNumber")?.value,
      psw: this.phoneForm.get("psw")?.value,
    };

    const signupResponse = await this._authService.signup(dto);
    this.isPending = false;

    if (!signupResponse.withError) {
      this.step = "otp";
      this.otpTimer = Constants.SecInMin;
      this.businessError = null;
      return;
    }

    if (signupResponse.errorCode == AuthErrorCode.PhoneNumberAlreadyUse) {
      this.businessError = this._translateService.instant("Login.Phone_already_in_use");
      this.step = "phone";
      return;
    }

    if (signupResponse.errorCode == AuthErrorCode.DisallowSmsSentTo) {
      this.businessError = this._translateService.instant("Login.Cannot_send_sms");
      return;
    }

    this.businessError = this._translateService.instant("Common.Unknown_error");
  }

  async submitOtp() {
    if (this.otpForm.invalid) {
      return;
    }

    // Check if captcha is not bypassed
    if (this.otpInputCount >= ValidateConstants.MaxOtpTries) {
      this.businessError = this._translateService.instant("Login.Captcha_not_passed");
      return;
    }

    this.isPending = true;
    const dto: ApprovePhoneDto = {
      phoneNumber: this.phoneCode + this.phoneForm.get("phoneNumber")?.value,
      code: this.otpForm.get("code")?.value,
    };
    const approveResponse = await this._authService.approvePhone(dto);
    this.isPending = false;

    if (!approveResponse.withError) {
      this.hideOtherVariants = false;
      const registrationSuccessMsg = this._translateService.instant("Login.Reg_success_message");
      this._toastService.success(registrationSuccessMsg);
      this.onClose();
      return;
    }

    if (approveResponse.errorCode == AuthErrorCode.IncorrectCode) {
      // If user input wrong OTP, then increment OTP inputs counter
      this.otpInputCount++;
      this.businessError = this._translateService.instant("Login.Wrong_code_or_expired");
      return;
    }

    this.businessError = this._translateService.instant("Common.Unknown_error");
  }

  async sendConfirmCode() {
    const dto: SendConfirmCodeDto = {
      phoneNumber: this.phoneCode + this.phoneForm.get("phoneNumber")?.value,
    };
    const response = await this._authService.sendConfirmCode(dto);
    this.otpTimer = Constants.ConfirmCodeLifeTimeInSec;
    if (response.withError && response.errorCode == AuthErrorCode.CodeRequestLimitExceeds) {
      this.otpTimer = TimeHelperUtil.getTimeDiffInSec(response.params!);
    }
  }

  public openSignInModal() {
    this.onClose();
    this._modalService.open(SignInModalComponent);
  }

  ngOnDestroy() {
    this.phoneForm.reset();
    // this.passwordForm.reset();
    this.otpForm.reset();
    this.step = "phone";
  }
}

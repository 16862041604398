import { animate, AUTO_STYLE, state, style, transition, trigger } from "@angular/animations";
import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { UserDto } from "src/app/common/DTO/users/user.dto";
import { EventTypeConstants } from "src/app/common/constants/event-type.constants";
import { MENU_ITEMS } from "src/app/common/constants/menu-items.constant";
import { RouteConstants } from "src/app/common/constants/route.constants";
import { AuthService } from "src/app/services/auth.service";
import { EventBusService } from "src/app/services/event-bus.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { ThemeService } from "src/app/services/theme.service";

@Component({
  selector: "app-client-sidebar",
  templateUrl: "./client-sidebar.component.html",
  styleUrls: ["./client-sidebar.component.css"],
  animations: [
    trigger("buttonExpand", [
      state("true", style({ width: AUTO_STYLE, visibility: AUTO_STYLE, margin: AUTO_STYLE })),
      state("false", style({ width: 0, visibility: "hidden", margin: "0" })),
      transition("false => true", animate("200ms")),
      transition("true => false", animate("200ms")),
    ]),
  ],
})
export class ClientSidebarComponent {
  public menuItems = MENU_ITEMS;
  public isFullMenu = true;
  public theme: "light" | "dark" = "light";

  private user = new UserDto();

  constructor(
    private _authService: AuthService,
    private _localStorage: LocalStorageService,
    private _router: Router,
    private _eventBusService: EventBusService,
    private _themeService: ThemeService
  ) {
    this._localStorage.getUserData().then(x => {
      if (x != null) this.user = x;
    });

    this._localStorage.getSidebarState().then(x => {
      this.isFullMenu = x;
    });

    // Change subdomain link on language change
    this._eventBusService.handle(EventTypeConstants.ChangeLanguage, (language: string) => {
      this.menuItems = this.menuItems.map(item => {
        let link = item.link;
        if (link.includes("language=")) {
          link = item.link.replace(/language=[a-z]{2}/, `language=${language}`);
        } else {
          link = `${item.link}?language=${language}`;
        }
        return { ...item, link };
      });
    });

    this._themeService.theme$.subscribe(theme => {
      this.theme = theme;
    });
  }

  public onLogout() {
    this.exit();
  }

  private async exit(): Promise<void> {
    await this._authService.logout();

    if (this.user.role.isAdmin) {
      this._router.navigateByUrl(`${RouteConstants.admin}/${RouteConstants.login}`);
    } else {
      this._router.navigateByUrl("/");
    }

    await this._localStorage.clearTokens();
    this._localStorage.removeUserData();
  }

  public toggleMenu() {
    this.isFullMenu = !this.isFullMenu;
    this._localStorage.saveSidebarState(this.isFullMenu);
  }
}

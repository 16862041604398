import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { Constants } from "src/app/common/constants/constants";
import { UrnConstants } from "src/app/common/constants/urn.constants";
import { ApiResponseDto } from "src/app/common/DTO/api-response.dto";
import { HttpResultDto } from "src/app/common/DTO/http-result.dto";
import { SendCryptoDto, SwapCryptoDto } from "src/app/common/DTO/wallets";
import { WalletDto } from "src/app/common/DTO/wallets/wallet.dto";
import { WalletErrorCode } from "src/app/common/enums/wallet-error-code.enum";
import { ErrorParserUtil } from "src/app/common/utils/error-parser.util";
import { EnvService } from "src/app/services/env.service";

@Injectable({
  providedIn: "root",
})
export class WalletService {
  private readonly _baseReqOpts: { headers: HttpHeaders };

  constructor(
    private readonly _envService: EnvService,
    private readonly _httpClient: HttpClient
  ) {
    this._baseReqOpts = {
      headers: new HttpHeaders(Constants.JsonContentTypeHeader),
    };
  }

  public async getMy() {
    const uri = `${this._envService.serverUrl}${UrnConstants.GetMyWallet}`;
    const result = new HttpResultDto<WalletErrorCode, WalletDto[] | null>(false);
    try {
      const response = (await firstValueFrom(this._httpClient.get(uri))) as ApiResponseDto<WalletDto[]>;
      result.params = response.data;
    } catch (e) {
      result.withError = true;

      const apiError = ErrorParserUtil.parse(e);
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  public async send(dto: SendCryptoDto): Promise<HttpResultDto<WalletErrorCode, string>> {
    const uri = `${this._envService.serverUrl}${UrnConstants.Send}`;
    const result = new HttpResultDto<WalletErrorCode, string>(false);
    try {
      const response = (await firstValueFrom(
        this._httpClient.post(uri, dto, this._baseReqOpts)
      )) as ApiResponseDto<string>;
      result.params = response.data;
    } catch (e) {
      result.withError = true;

      const apiError = ErrorParserUtil.parse(e);
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  public async swap(dto: SwapCryptoDto) {
    const uri = `${this._envService.serverUrl}${UrnConstants.Swap}`;
    const result = new HttpResultDto<WalletErrorCode, string>(false);

    try {
      const response = (await firstValueFrom(
        this._httpClient.post(uri, dto, this._baseReqOpts)
      )) as ApiResponseDto<string>;
      result.params = response.data;
    } catch (e) {
      result.withError = true;

      const apiError = ErrorParserUtil.parse(e);
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  private getErrorCode(error: string): WalletErrorCode {
    const sendDisabledRegex = /^Sending currency .+ is disabled$/;
    if (sendDisabledRegex.test(error)) {
      return WalletErrorCode.SendDisabled;
    }

    const notEnoughRegex = /^Not enough .+$/;
    if (notEnoughRegex.test(error)) {
      return WalletErrorCode.NotEnoughCurrency;
    }

    switch (error) {
      case Constants.InternalServerError:
        return WalletErrorCode.InternalError;
      case Constants.Unauthorized:
        return WalletErrorCode.Unauthorized;
      case "Blockchain error":
        return WalletErrorCode.BlockchainError;
      case "Send limit reached today":
        return WalletErrorCode.SendLimitReached;
      case "Unable to read Blockchain service response: Blockchain service error: Invalid recipient address provided":
        return WalletErrorCode.InvalidAddress;
      default:
        return WalletErrorCode.InternalError;
    }
  }
}

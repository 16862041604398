import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { AddAdminDto } from "src/app/common/DTO/auth/add-admin-dto";
import { RoleDto } from "src/app/common/DTO/roles";
import { AdminDto } from "src/app/common/DTO/users/admin.dto";
import { Constants } from "src/app/common/constants/constants";
import { ModalConstants } from "src/app/common/constants/modal-ids.constants";
import { ValidateConstants } from "src/app/common/constants/validate.constants";
import { AccountRole } from "src/app/common/enums/account-role.enum";
import { AdminPermission } from "src/app/common/enums/admin-permission.enum";
import { UserErrorCode } from "src/app/common/enums/user-error-code.enum";
import { lengthEqualValidator } from "src/app/common/validators/length-equal.validator";
import { numericStrValidator } from "src/app/common/validators/numeric-str.validator";
import { ModalService } from "src/app/components/_modal";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { RolesService } from "src/app/services/roles.service";
import { ToastService } from "src/app/services/toast.service";
import { UserService } from "src/app/services/user.service";

@Component({
  templateUrl: "./admin-admins.component.html",
  styleUrls: ["./admin-admins.component.css"],
})
export class AdminAdminsComponent implements OnInit {
  public admins: AdminDto[] = [];
  public roles: RoleDto[] = [];
  public page: number = 1;
  public pageSize: number = 100;
  public totalCount: number = 0;
  public ModalConstants = ModalConstants;
  public isSuperAdmin: boolean = false;
  public hasInteractionPermissions: boolean = false;
  public showSuperConfirmation: boolean = false;
  public isPending: boolean = false;

  public addAdminForm = new FormGroup({
    name: new FormControl("", [Validators.required]),
    phoneNumber: new FormControl("", [
      Validators.required,
      numericStrValidator(),
      lengthEqualValidator(ValidateConstants.PhoneLengthWithoutCode),
    ]),
    roleId: new FormControl<number | null>(null, [Validators.required]),
    login: new FormControl("", [Validators.required]),
    psw: new FormControl("", [
      Validators.required,
      Validators.minLength(ValidateConstants.MinPswLength),
      Validators.maxLength(60),
    ]),
  });
  public adminPsw = new FormControl("", [
    Validators.required,
    Validators.minLength(ValidateConstants.MinPswLength),
    Validators.maxLength(60),
  ]);

  public addErrorMessage: string = "";

  public adminsTabs = [
    { id: 1, title: "Admin.Admins.Title" },
    { id: 2, title: "Admin.Admins.Roles" },
  ];
  public currentTab = this.adminsTabs[0];

  constructor(
    private readonly _localStorage: LocalStorageService,
    private readonly _modalService: ModalService,
    private readonly _translateService: TranslateService,
    private readonly _userService: UserService,
    private readonly _toastService: ToastService,
    private readonly _rolesService: RolesService
  ) {}

  async ngOnInit(): Promise<void> {
    this.isSuperAdmin = await this._localStorage.isSuperAdmin();
    this.hasInteractionPermissions = await this._localStorage.adminHasPermission(
      AdminPermission.AdminInteraction
    );
    await this.requestAdmins();
    await this.requestRoles();
  }

  public get tabs() {
    return this.adminsTabs.map(tab => tab.title);
  }

  public get roleNames() {
    return this.roles.filter(role => role.isAdmin && role.name !== "SuperAdmin").map(role => role.name);
  }

  public canRoleBeChanged(admin: AdminDto) {
    return admin.role !== AccountRole.SuperAdmin && this.hasInteractionPermissions;
  }

  public canDeleteAdmin(admin: AdminDto) {
    return admin.role !== AccountRole.SuperAdmin && this.isSuperAdmin;
  }

  public onTabSelect(tabNumber: number) {
    this.currentTab = this.adminsTabs[tabNumber];
  }

  public onClickAdd() {
    if (this.currentTab.id === 1) {
      this.openAddAdminModal();
    }
    if (this.currentTab.id === 2) {
      this.openAddRoleModal();
    }
  }

  public openAddAdminModal() {
    this._modalService.open(ModalConstants.AdminAddAdmin);
  }

  public openConfirmation() {
    this.showSuperConfirmation = true;
  }

  public openAddRoleModal() {
    this._modalService.open(ModalConstants.AdminAddRole);
  }

  public get selectedRoleNameOnAdd() {
    return this.roles.find(role => role.id === this.addAdminForm.value.roleId)?.name;
  }

  public handleChangeRoleOnAdd(roleName: string) {
    const roleId = this.roles.find(role => role.name === roleName)?.id!;
    this.addAdminForm.controls.roleId.setValue(roleId);
  }

  public clearForms() {
    this.addAdminForm.reset();
    this.adminPsw.reset();
    this.addErrorMessage = "";
    this.showSuperConfirmation = false;
  }

  public async handleAddAdmin() {
    const phoneNumber = Constants.UzsPhoneCode + this.addAdminForm.value.phoneNumber;
    const dto: AddAdminDto = {
      roleId: this.addAdminForm.value.roleId as number,
      name: this.addAdminForm.value.name as string,
      phoneNumber,
      login: this.addAdminForm.value.login as string,
      psw: this.addAdminForm.value.psw as string,
    };
    this.isPending = true;

    const res = await this._userService.addAdmin(dto);

    if (res && res.withError) {
      switch (res.errorCode) {
        case UserErrorCode.PhoneOrNameAlreadyUse:
          this.addErrorMessage = "Admin.Admins.Add_admin_phone_or_name_in_use";
          break;
        default:
          this.addErrorMessage = "Admin.Admins.Add_admin_error";
          break;
      }
      return;
    }

    this.requestAdmins();
    this._modalService.close(ModalConstants.AdminAddAdmin);
    const msg = this._translateService.instant("Admin.Admins.Add_admin_success");
    this._toastService.success(msg);
    this.isPending = false;
  }

  public async handleDeleteAdmin(adminId: number) {
    this.isPending = true;

    const res = await this._userService.deleteAdmin(adminId);

    if (res.withError) {
      const msg = this._translateService.instant("Admin.Admins.Delete_admin_error");
      this._toastService.error(msg);
    } else {
      const msg = this._translateService.instant("Admin.Admins.Delete_admin_success");
      this._toastService.success(msg);
      this.requestAdmins();
    }
    this.isPending = false;
  }

  public async handleChangeRole(adminId: number, roleName: string) {
    const roleId = this.roles.find(role => role.name === roleName)?.id!;

    this.isPending = true;

    const res = await this._userService.updateAdminRole(adminId, roleId);

    if (res.withError) {
      const msg = this._translateService.instant("Admin.Admins.Change_role_error");
      this._toastService.error(msg);
    } else {
      const msg = this._translateService.instant("Admin.Admins.Change_role_success");
      this._toastService.success(msg);
      this.requestAdmins();
    }
    this.isPending = false;
  }

  public formControlError(control: string) {
    const field = this.addAdminForm.get(control);

    if (!field || !field?.touched) {
      return null;
    }

    if (field.hasError("required")) {
      return this._translateService.instant("Common.Field_not_filled");
    }

    if (control === "psw") {
      if (field.hasError("minlength") || field.hasError("maxLength")) {
        return this._translateService.instant("Admin.Admins.Add_admin_min_psw_length");
      }
      if (field.hasError("maxlength")) {
        return this._translateService.instant("Admin.Admins.Add_admin_max_psw_length");
      }
    }

    if (control === "phoneNumber") {
      if (field.hasError("numericStr")) {
        return this._translateService.instant("Login.Phone_numbers_error");
      }
      if (field.hasError("lengthEqual")) {
        return this._translateService.instant("Login.Phone_length_error");
      }
    }

    return null;
  }

  public onRolesChange() {
    this.requestAdmins();
  }

  private async requestAdmins() {
    const res = await this._userService.getAdmins(this.pageSize, this.page);
    this.admins = res.params?.items ?? [];
    this.totalCount = res.params?.totalCount ?? 0;
  }

  private async requestRoles() {
    const res = await this._rolesService.getAllRoles();
    this.roles = res.params ?? [];
  }
}

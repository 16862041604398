<aside class="relative h-full w-[16.875rem] bg-white py-8 pl-12 shadow-xl">
  <div class="sticky top-8">
    <nav class="flex flex-col gap-2">
      <a
        *ngFor="let link of sidebarLinks"
        [routerLink]="link.link"
        class="flex items-center gap-x-2.5 rounded-l-lg px-2.5 py-3 text-sm font-medium text-slate-900 transition-colors hover:bg-green-400/20"
        routerLinkActive="bg-green-400/10 border-r-2 border-green-400 !text-green-400"
      >
        <app-svg-icon [name]="link.icon" class="h-5 w-5" />
        <span class="text-slate-900">{{ link.title | translate }}</span>
      </a>
      <!-- <a class="btn btn_big btn_pale" routerLink="/admin/kyc" routerLinkActive="btn_active"> KYC </a> -->
    </nav>
  </div>
</aside>

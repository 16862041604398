import { Component, Input } from "@angular/core";

@Component({
  selector: "app-fractional-number",
  templateUrl: "./fractional-number.component.html",
  styleUrls: ["./fractional-number.component.css"],
})
export class FractionalNumberComponent {
  @Input() value: number | string = "";

  constructor() {}

  public get dividedValue() {
    const [integer, fraction] = this.value.toString().split(".");
    return {
      integer,
      fraction,
    };
  }
}

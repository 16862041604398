import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { Constants } from "src/app/common/constants/constants";
import { UrnConstants } from "src/app/common/constants/urn.constants";
import { ApiResponseDto } from "src/app/common/DTO/api-response.dto";
import { AddAdminDto } from "src/app/common/DTO/auth/add-admin-dto";
import { HttpResultDto } from "src/app/common/DTO/http-result.dto";
import { PagedListDto } from "src/app/common/DTO/paged-list.dto";
import { AdminDto } from "src/app/common/DTO/users/admin.dto";
import { UserDto } from "src/app/common/DTO/users/user.dto";
import { UpdateRiskLevelDto } from "src/app/common/DTO/users/user.update-risk-level.dto";
import { UserActivationFilterOptions } from "src/app/common/enums/user-activation-filter-option.enum";
import { UserErrorCode } from "src/app/common/enums/user-error-code.enum";
import { UserIdentificationFilterOptions } from "src/app/common/enums/user-identification-filter-options.enum";
import { ErrorParserUtil } from "src/app/common/utils/error-parser.util";
import { EnvService } from "src/app/services/env.service";

@Injectable({
  providedIn: "root",
})
export class UserService {
  private readonly _baseReqOpts: { headers: HttpHeaders };
  private readonly _formDataReqOpts: { headers: HttpHeaders };

  constructor(
    private readonly _httpClient: HttpClient,
    private readonly _envService: EnvService
  ) {
    this._baseReqOpts = {
      headers: new HttpHeaders(Constants.JsonContentTypeHeader),
    };
    this._formDataReqOpts = {
      headers: new HttpHeaders(Constants.FormDataContentTypeHeader),
    };
  }

  public async uploadAvatar(file: File): Promise<HttpResultDto<UserErrorCode, null>> {
    const uri = `${this._envService.serverUrl}${UrnConstants.UploadAvatar}`;
    const result = new HttpResultDto<UserErrorCode, null>(false);

    const data = new FormData();
    data.append("avatar", file);
    try {
      await firstValueFrom(this._httpClient.post(uri, data, this._formDataReqOpts));
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getUserErrorCode(apiError.msg);
    }

    return result;
  }

  public async updateAvatar(avatarId: string): Promise<HttpResultDto<UserErrorCode, null>> {
    const uri = `${this._envService.serverUrl}${UrnConstants.UploadAvatar}/${avatarId}`;
    const result = new HttpResultDto<UserErrorCode, null>(false);

    try {
      await firstValueFrom(this._httpClient.post(uri, {}, this._formDataReqOpts));
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getUserErrorCode(apiError.msg);
    }

    return result;
  }

  public async updateUserRiskLevel(dto: UpdateRiskLevelDto): Promise<HttpResultDto<UserErrorCode, null>> {
    const uri = `${this._envService.serverUrl}${UrnConstants.UpdateUserRiskLevel}`;
    const result = new HttpResultDto<UserErrorCode, null>(false);
    try {
      await firstValueFrom(this._httpClient.post(uri, dto, this._formDataReqOpts));
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getUserErrorCode(apiError.msg);
    }

    return result;
  }

  public async getMe(): Promise<HttpResultDto<UserErrorCode, UserDto | null>> {
    const uri = `${this._envService.serverUrl}${UrnConstants.GetMe}`;
    const result = new HttpResultDto<UserErrorCode, UserDto | null>(false);

    try {
      const response = (await firstValueFrom(this._httpClient.get(uri))) as ApiResponseDto<UserDto>;
      response.data.createdAt = new Date(response.data.createdAt);
      result.params = response.data;
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getUserErrorCode(apiError.msg);
    }

    return result;
  }

  public async getAllUsers(
    searchStr: string = "",
    size: number,
    page: number,
    filter: UserIdentificationFilterOptions = UserIdentificationFilterOptions.All,
    activationFilter: UserActivationFilterOptions = UserActivationFilterOptions.All
  ): Promise<HttpResultDto<UserErrorCode, PagedListDto<UserDto> | null>> {
    // const params = `?searchStr=${searchStr}&identificationFilter=${filter}&activationFilter=${activationFilter}&size=${size}&index=${page}`;

    const urlSearchParams = new URLSearchParams({
      filter: filter.toString(),
      size: size.toString(),
      index: page.toString(),
    });
    if (searchStr) {
      urlSearchParams.append("searchStr", searchStr);
    }

    const uri = `${this._envService.serverUrl}${UrnConstants.GetAllUsers}?${urlSearchParams.toString()}`;
    const result = new HttpResultDto<UserErrorCode, PagedListDto<UserDto> | null>(false);

    try {
      const response = (await firstValueFrom(
        this._httpClient.get(uri)
      )) as ApiResponseDto<PagedListDto<UserDto> | null>;
      result.params = response.data;
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getUserErrorCode(apiError.msg);
    }

    return result;
  }

  public async getUserById(id: number): Promise<HttpResultDto<UserErrorCode, UserDto | null>> {
    const uri = `${this._envService.serverUrl}${UrnConstants.UsersUrn}/${id}`;
    const result = new HttpResultDto<UserErrorCode, UserDto | null>(false);

    try {
      const response = (await firstValueFrom(this._httpClient.get(uri))) as ApiResponseDto<UserDto>;
      response.data.createdAt = new Date(response.data.createdAt);
      result.params = response.data;
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getUserErrorCode(apiError.msg);
    }

    return result;
  }

  public async getAdmins(
    size: number,
    index: number
  ): Promise<HttpResultDto<UserErrorCode, PagedListDto<AdminDto> | null>> {
    const params = `?size=${size}&index=${index}`;
    const uri = `${this._envService.serverUrl}${UrnConstants.GetAdmins}${params}`;
    const result = new HttpResultDto<UserErrorCode, PagedListDto<AdminDto> | null>(false);

    try {
      const response = (await firstValueFrom(
        this._httpClient.get(uri)
      )) as ApiResponseDto<PagedListDto<AdminDto> | null>;
      result.params = response.data;
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getUserErrorCode(apiError.msg);
    }

    return result;
  }

  public async addAdmin(dto: AddAdminDto): Promise<HttpResultDto<UserErrorCode, null>> {
    const uri = `${this._envService.serverUrl}${UrnConstants.GetAdmins}`;

    const result = new HttpResultDto<UserErrorCode, null>(false);

    try {
      await firstValueFrom(this._httpClient.post(uri, dto, this._baseReqOpts));
    } catch (e) {
      result.withError = true;
      const apiError = ErrorParserUtil.parse(e);
      result.errorCode = this.getUserErrorCode(apiError.msg);
    }

    return result;
  }

  public async deleteAdmin(adminId: number): Promise<HttpResultDto<UserErrorCode, null>> {
    const uri = `${this._envService.serverUrl}${UrnConstants.GetAdmins}/${adminId}`;
    const result = new HttpResultDto<UserErrorCode, null>(false);

    try {
      await firstValueFrom(this._httpClient.delete(uri));
    } catch (e) {
      result.withError = true;
      const apiError = ErrorParserUtil.parse(e);
      result.errorCode = this.getUserErrorCode(apiError.msg);
    }

    return result;
  }

  public async updateAdminRole(adminId: number, roleId: number): Promise<HttpResultDto<UserErrorCode, null>> {
    const uri = `${this._envService.serverUrl}${UrnConstants.GetAdmins}/${adminId}/${roleId}`;
    const result = new HttpResultDto<UserErrorCode, null>(false);

    try {
      await firstValueFrom(this._httpClient.put(uri, null));
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getUserErrorCode(apiError.msg);
    }

    return result;
  }

  public async deleteUserIdentification(userId: number): Promise<HttpResultDto<UserErrorCode, null>> {
    const uri = `${this._envService.serverUrl}${UrnConstants.DeleteKyc}/${userId}`;
    const result = new HttpResultDto<UserErrorCode, null>(false);

    try {
      await firstValueFrom(this._httpClient.delete(uri));
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getUserErrorCode(apiError.msg);
    }

    return result;
  }

  public async deactivateUser(userId: number): Promise<HttpResultDto<UserErrorCode, null>> {
    const uri = `${this._envService.serverUrl}${UrnConstants.UsersUrn}/${userId}`;
    const result = new HttpResultDto<UserErrorCode, null>(false);

    try {
      await firstValueFrom(this._httpClient.delete(uri));
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getUserErrorCode(apiError.msg);
    }

    return result;
  }

  private getUserErrorCode(error: string): UserErrorCode {
    switch (error) {
      case Constants.InternalServerError:
        return UserErrorCode.InternalError;
      case Constants.Unauthorized:
        return UserErrorCode.Unauthorized;
      case "Phone number or login already in use":
        return UserErrorCode.PhoneOrNameAlreadyUse;
      case "Admin role not found":
        return UserErrorCode.AdminRoleNotFound;
      default:
        return UserErrorCode.InternalError;
    }
  }
}

<section class="overflow-hidden rounded-2xl bg-gray-25 dark:bg-slate-800">
  <div class="px-6 pt-6">
    <div class="flex items-center justify-between">
      <p class="flex items-center gap-x-2.5 text-xl font-semibold max-md:text-lg">
        Баланс
        <button class="w-6">
          <app-svg-icon name="eye-on" />
        </button>
      </p>

      <app-dropdown [items]="['Все время']">
        <button
          class="flex items-center justify-between gap-x-4 rounded-xl border-2 border-gray-100 bg-gray-25 px-4 py-2 font-semibold text-text-secondary dark:border-slate-700 dark:bg-slate-800"
        >
          <p>Все время</p>
          <app-svg-icon name="chevron" class="block h-4 w-4" />
        </button>
      </app-dropdown>
    </div>

    <div class="flex items-end gap-x-1">
      <div class="text-6xl font-semibold max-md:text-[2.5rem]">
        <app-fractional-number [value]="totalUzsBalance | mfeCustom: { currency: CryptoSymbol.Uzs }" /> USZ
      </div>
      <div class="flex items-center gap-x-2 text-xl font-semibold text-green-400">
        <app-svg-icon name="ticker-up" class="w-6" />
        {{ 0 | mfeCustom }}%
      </div>
    </div>
  </div>

  <div
    class="relative mb-1 mt-8 max-h-[13.25rem] w-full pb-[calc(100%/4)] max-md:max-h-[17.5rem] max-md:pb-[calc(100%/2)]"
  >
    <canvas
      baseChart
      type="line"
      [data]="chartData"
      [options]="chartOptions"
      class="absolute left-0 top-0 h-full w-full"
    ></canvas>
  </div>
</section>

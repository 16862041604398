<div class="relative rounded-xl bg-gray-100 p-1.5 dark:bg-slate-800 max-md:p-1 max-md:dark:bg-slate-700">
  <span
    class="slider"
    [ngClass]="{ 'translate-x-0': currentLanguage === 'ru', 'translate-x-full': currentLanguage === 'uz' }"
  ></span>

  <div class="relative flex items-center">
    <button
      class="text-base font-bold text-text-primary dark:text-white"
      [ngClass]="{ 'px-5 py-2': size === 'md', 'px-3 py-1 text-xs': size === 'sm' }"
      (click)="changeLanguage(LanguageConstants.RUSSIAN)"
    >
      Py
    </button>
    <button
      class="font-bold text-gray-900 dark:text-white"
      [ngClass]="{ 'px-5 py-2': size === 'md', 'px-3 py-1 text-xs': size === 'sm' }"
      (click)="changeLanguage(LanguageConstants.UZBEK)"
    >
      Uz
    </button>
  </div>
</div>

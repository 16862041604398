<aside class="sticky top-6 flex h-[calc(100vh-1.5rem)] flex-col p-6 pt-0">
  <app-logo [theme]="theme" class="px-4 py-3" />

  <div class="mt-6 grid grid-rows-1 gap-y-1">
    <ng-container *ngFor="let item of menuItems">
      <app-menu-link-button [item]="item" [isFullMenu]="isFullMenu" />
    </ng-container>
  </div>

  <!-- <button
    class="flex items-center rounded-tw border border-border-input-color stroke-text-primary px-7 py-5 hover:border-danger-hover hover:bg-danger-hover hover:stroke-text-secondary hover:text-text-secondary"
    (click)="onLogout()"
  >
    <app-svg-icon name="menu/exit-outline" class="h-8 w-8 shrink-0" />
    <span
      [@buttonExpand]="isFullMenu"
      class="ml-5 overflow-hidden whitespace-nowrap text-sm font-semibold uppercase"
    >
      {{ "Header.Logout" | translate }}
    </span>
  </button> -->

  <app-theme-selector class="mx-auto mt-auto" />
</aside>

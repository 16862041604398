<section class="rounded-2xl bg-gray-25 px-3 py-6 dark:bg-slate-800 max-md:px-2 max-md:py-4">
  <h2 class="subtitle px-3 py-1">Доступные активы</h2>

  <div class="mt-6 space-y-1">
    <!-- TODO: Ng for -->
    <div
      class="flex items-center justify-between gap-x-4 rounded-2xl border border-transparent px-3 py-5 transition-colors hover:border-gray-100 dark:hover:border-slate-700 max-md:px-2 max-md:py-4"
    >
      <div class="flex items-center gap-x-5">
        <app-currency-icon size="sm" />
        <div>
          <p class="text-base font-semibold">Test</p>
          <p class="text-xs font-bold text-text-secondary">TST</p>
        </div>
      </div>

      <div class="relative max-h-[3rem] w-full pb-[calc(100%/5)] max-md:max-h-[2.125rem]">
        <canvas
          baseChart
          type="line"
          [data]="chartData"
          [options]="chartOptions"
          class="absolute left-0 top-0 h-full w-full"
        ></canvas>
      </div>

      <div class="text-end">
        <p class="text-base font-semibold">${{ 0 | mfeCustom }}</p>
        <p class="flex items-center text-sm font-semibold text-green-400">
          <app-svg-icon name="ticker-up" class="w-6" />
          {{ 0 | mfeCustom }}%
        </p>
      </div>
    </div>
  </div>
</section>

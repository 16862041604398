import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { LimitPanelSettingsDto } from "src/app/common/DTO/limit-panel/limit-panel-settings.dto";
import { ModalConstants } from "src/app/common/constants/modal-ids.constants";
import { AdminPermission } from "src/app/common/enums/admin-permission.enum";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { LimitPanelService } from "src/app/services/limit-panel.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { ToastService } from "src/app/services/toast.service";

@Component({
  selector: "app-limits-block",
  templateUrl: "./limits-block.component.html",
  styleUrls: ["./limits-block.component.css"],
})
export class LimitsBlockComponent implements OnInit {
  @Input() public selectedCurrency: CryptoSymbol = CryptoSymbol.Trx;

  public ModalConstants = ModalConstants;
  public limits: LimitPanelSettingsDto[] = [];
  public isPending = false;
  public currencies: CryptoSymbol[] = [];
  public hasInteractionPermissions: boolean = false;

  public limitsForm: FormGroup;

  constructor(
    private readonly _localStorage: LocalStorageService,
    private readonly _translateService: TranslateService,
    private readonly _toastService: ToastService,
    private readonly _modalService: NgbModal,
    private readonly _limitService: LimitPanelService
  ) {
    this.limitsForm = new FormGroup({
      currency: new FormControl(null),
      maxBuyAmount: new FormControl(null, [Validators.required, Validators.min(1)]),
      minBuyAmount: new FormControl(null, [Validators.required, Validators.min(1)]),
      maxSellAmount: new FormControl(null, [Validators.required, Validators.min(1)]),
      minSellAmount: new FormControl(null, [Validators.required, Validators.min(1)]),
      maxAcquiringAmountWithoutConfirm: new FormControl(null, [Validators.required, Validators.min(1)]),
      maxWithdrawalPerDay: new FormControl(null, [Validators.required, Validators.min(1)]),
      maxWithdrawalPerTx: new FormControl(null, [Validators.required, Validators.min(1)]),
      minWithdrawalPerTx: new FormControl(null, [Validators.required, Validators.min(1)]),
      maxTxAmountWithoutConfirm: new FormControl(null, [Validators.required, Validators.min(1)]),
    });
  }

  async ngOnInit(): Promise<void> {
    this.hasInteractionPermissions = await this._localStorage.adminHasPermission(
      AdminPermission.PlatformInteraction
    );
    await this.requestLimits();
  }

  public getCurrencyName(currency: CryptoSymbol) {
    switch (currency) {
      case CryptoSymbol.Trx:
        return "TRX";
      case CryptoSymbol.Usdt:
        return "USDT (TRX)";
      case CryptoSymbol.Matic:
        return "MATIC";
      case CryptoSymbol.PolygonUsdt:
        return "USDT (Polygon)";
      case CryptoSymbol.Ton:
        return "TON";
      case CryptoSymbol.TonUsdt:
        return "USDT (TON)";
      case CryptoSymbol.Not:
        return "NOT";
      case CryptoSymbol.Bitcoin:
        return "BTC";
      default:
        return "";
    }
  }

  public getLimits(currency: CryptoSymbol) {
    return this.limits.find(x => x.currency === currency);
  }

  public handleOpenModal(currency: CryptoSymbol, content: any) {
    const currentLimit = this.limits.find(x => x.currency === currency);
    this.limitsForm.setValue(currentLimit!);
    this._modalService.open(content);
  }

  public handleCloseModal() {
    this._modalService.dismissAll();
    this.limitsForm.reset();
  }

  public async handleSaveLimit() {
    const dto: LimitPanelSettingsDto = {
      ...this.limitsForm.value,
    };
    this.isPending = true;
    const res = await this._limitService.updateLimits(dto);

    if (res.withError) {
      const errorMsg = this._translateService.instant("Common.Unknown_error");
      this._toastService.show(errorMsg);
    } else {
      const successMsg = this._translateService.instant("Common.Updated");
      this._toastService.success(successMsg);
    }

    this.isPending = false;
    this.handleCloseModal();
    this.requestLimits();
  }

  public getFieldError(field: string) {
    const formField = this.limitsForm.get(field);

    if (formField?.value == null) {
      return null;
    }
    if (formField?.hasError("required")) {
      return this._translateService.instant("Common.Field_not_filled");
    }
    if (formField?.hasError("min")) {
      return this._translateService.instant("Admin.Home.Min_value_error", {
        value: formField?.errors?.["min"]?.min,
      });
    }
    return null;
  }

  private async requestLimits() {
    const res = await this._limitService.getLimits();
    if (res.withError) {
      return;
    }
    this.limits = res.params!;
    this.currencies = res.params!.map(x => x.currency);
  }
}

<ng-container *ngIf="!isLoading; else loader">
  <div class="admin-body">
    <section class="flex items-center justify-between">
      <div>
        <app-back-button link="/admin/users"></app-back-button>
        <h3 class="half-title mt-5 uppercase">
          {{ userFullName ? userFullName : ("Admin.Users.User" | translate) }}
        </h3>
        <div class="mt-2 text-lg font-medium text-slate-400">{{ 0 | mfeCustom }} USDT</div>
      </div>

      <div class="flex items-center gap-x-6">
        <button
          class="btn-flat"
          [class.btn-flat_danger]="isUserBlocked"
          (click)="handleDownloadReport()"
          [disabled]="!hasInteractionPermissions || !userData.kyc"
        >
          <app-svg-icon name="admin/download" class="aspect-square w-5" />
          {{ "Admin.Users.Download_user_data" | translate }}
        </button>
        <button
          class="btn-flat"
          [class.btn-flat_danger]="true"
          (click)="handleDeleteIdentification()"
          [disabled]="!hasInteractionPermissions || !userData.kyc || !isUserActive"
        >
          <app-svg-icon name="trash-bin" class="aspect-square w-5" />
          {{ "Admin.Users.Delete_identification" | translate }}
        </button>
        <button
          class="btn-flat btn-flat_danger"
          (click)="handleDeactivate()"
          *ngIf="!isUserBlockedForever"
          [disabled]="!isUserActive || !hasInteractionPermissions"
        >
          <app-svg-icon name="admin/close-circle" class="aspect-square w-5" />
          {{ "Admin.Users.Deactivate_account" | translate }}
        </button>
        <button
          class="btn-flat"
          (click)="handleBlockUnblock()"
          *ngIf="!isUserBlockedForever"
          [disabled]="blockInProgress || !hasInteractionPermissions || !isUserActive"
        >
          <app-svg-icon name="admin/lock" class="aspect-square w-5" />
          {{ isUserBlocked ? ("Admin.Users.Unblock" | translate) : ("Admin.Users.Block" | translate) }}
        </button>
      </div>
    </section>

    <div class="grid grid-cols-4 gap-2">
      <app-balance-card *ngFor="let balance of userData.balances" [balanceItem]="balance" [rates]="rates" />
    </div>

    <h3 class="half-title">Информация</h3>

    <section class="grid grid-cols-2 gap-x-2">
      <div class="space-y-2">
        <div class="box">
          <p class="mb-3 text-lg font-medium text-black dark:text-white">
            {{ "Admin.Users.Full_name" | translate }}
          </p>
          <p *ngIf="userFullName; else userNotVerified" class="text-sm text-slate-400 dark:text-slate-200">
            {{ userData.kyc?.firstName + " " + userData.kyc?.lastName }}
          </p>
        </div>

        <div class="box">
          <p class="mb-3 text-lg font-medium text-black dark:text-white">
            {{ "Admin.Users.Phone_number" | translate }}
          </p>
          <p class="text-sm text-slate-400 dark:text-slate-200">
            {{ userData.phoneNumber | phoneDisplayer }}
          </p>
        </div>

        <div class="box">
          <p class="mb-3 text-lg font-medium text-black dark:text-white">
            {{ "Admin.Users.Bank_cards" | translate }}
          </p>
          <ng-container *ngIf="userData.cards?.length; else noCardsAdded">
            <div *ngFor="let card of userData.cards">
              <p class="text-sm text-slate-400 dark:text-slate-200">{{ card }}</p>
            </div>
          </ng-container>
        </div>

        <div class="box">
          <p class="mb-3 text-lg font-medium text-black dark:text-white">
            {{ "Admin.Users.Risk_level" | translate }}
          </p>
          <div ngbDropdown class="dropdown">
            <div class="flex w-full items-center justify-between text-sm text-slate-400 dark:text-slate-200">
              <p [ngClass]="userRiskLevel?.color">
                {{ userRiskLevel?.name || "" | translate }}
              </p>
              <button *ngIf="hasInteractionPermissions" ngbDropdownToggle class="w-5">
                <app-svg-icon name="edit" class="block" />
              </button>
            </div>
            <div ngbDropdownMenu>
              <button
                ngbDropdownItem
                *ngFor="let item of userRiskLevelsList"
                (click)="onRiskLevelSelect(item.value)"
                [ngClass]="item.color"
              >
                <p>{{ item.name | translate }}</p>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="userData?.wallets?.length" class="box space-y-3">
        <h4 class="altitle">Адреса</h4>

        <div *ngFor="let wallet of userData.wallets">
          <p class="text-sm font-medium text-slate-400 dark:text-slate-200">
            {{ getNetworkName(wallet.network) }}
          </p>
          <div *ngIf="userData.wallets" class="flex w-full items-center justify-between">
            <a
              target="_blank"
              [href]="getWalletLink(wallet.network, wallet.address)"
              class="truncate text-sm font-medium text-green-400"
            >
              {{ wallet.address }}
            </a>
            <app-copy-btn [value]="wallet.address" />
          </div>
        </div>
      </div>
    </section>
  </div>

  <section class="mt-6">
    <h3 class="half-title">{{ "Transaction.Transaction_history" | translate }}</h3>

    <div class="mb-6 mt-3 grid grid-cols-[1fr_auto] gap-x-6">
      <app-tabs
        [tabs]="tokenSwitchValues"
        (onSelect)="switchTxType($event)"
        variant="panels"
        class="block overflow-x-auto overflow-y-hidden [&_button]:uppercase"
      />
      <a [routerLink]="'transactions'" class="btn-flat">
        {{ "Transaction.All_transactions" | translate }}
        <app-svg-icon name="chevron" class="block w-5 -rotate-90" />
      </a>
    </div>

    <!-- TODO: make table -->
    <!-- <table class="adminTable"> -->
    <!-- <thead>
        <tr>
          <th scope="col">{{ "Admin.Trans.Sum" | translate }}</th>
          <th scope="col">{{ "Admin.Trans.Date" | translate }}</th>
          <th scope="col">{{ "Admin.Trans.Type" | translate }}</th>
          <th scope="col">{{ "Admin.Trans.Wallet" | translate }}</th>
          <th scope="col">{{ "Admin.Trans.Phone_number" | translate }}</th>
          <th scope="col">{{ "Admin.Trans.Status" | translate }}</th>
          <th scope="col">{{ "Admin.Trans.Card_number" | translate }}</th>
          <th scope="col">{{ "Admin.Trans.Revert" | translate }}</th>
          <th scope="col">{{ "Admin.Trans.Compliance" | translate }}</th>
        </tr>
      </thead> -->

    <ng-container *ngIf="!isTxsLoading; else txLoader">
      <div *ngIf="hasTxs; else noTrans" class="grid grid-rows-1">
        <ng-container *ngIf="isBuySellTab; else bcTxs">
          <app-crypto-transaction-list-item *ngFor="let item of buySellTxs" [txDto]="item" />
        </ng-container>

        <ng-template #bcTxs>
          <app-transaction-list-item
            *ngFor="let item of txs"
            [txDto]="item"
            class="border-t border-gray-100 first:border-none dark:border-slate-700"
          />
        </ng-template>
      </div>
      <ng-template #noTrans>
        <div class="text-center text-base">{{ "Transaction.No_transactions" | translate }}</div>
      </ng-template>
    </ng-container>

    <ng-template #txLoader>
      <app-transaction-list-item-skeleton></app-transaction-list-item-skeleton>
    </ng-template>
    <!-- </table> -->
  </section>
</ng-container>

<ng-template #loader>
  <app-loader></app-loader>
</ng-template>

<ng-template #userNotVerified>
  <p class="text-sm text-slate-400 dark:text-slate-200">{{ "Admin.Users.User_not_verified" | translate }}</p>
</ng-template>

<ng-template #noCardsAdded>
  <p class="text-sm text-slate-400 dark:text-slate-200">{{ "Admin.Users.No_bank_cards" | translate }}</p>
</ng-template>

<section class="admin-body" *ngIf="!isLoading; else loader">
  <div class="flex items-center justify-between">
    <h3 class="half-title">{{ "Admin.Security.Terrorists.Title" | translate }}</h3>

    <div class="flex items-center gap-x-6">
      <button class="btn-flat" (click)="handleOpenModal()" [disabled]="!hasInteractionPermissions">
        <app-svg-icon name="plus" class="w-5" />
        {{ "Admin.Security.Terrorists.Add" | translate }}
      </button>

      <input type="file" #fileInput style="display: none" (change)="onFileSelected($event)" />
      <button class="btn-flat" (click)="fileInput.click()" [disabled]="!hasInteractionPermissions">
        <app-svg-icon name="admin/download" class="w-5" />
        {{ "Admin.Security.Terrorists.Import" | translate }}
      </button>

      <button class="btn-flat" (click)="requestScannedTerrors()" [disabled]="!hasInteractionPermissions">
        <app-svg-icon name="scan-icon" class="w-5" />
        {{ "Admin.Security.Terrorists.Scan" | translate }}
      </button>
    </div>
  </div>

  <!-- <div class="flex items-center gap-2">
    <span class="text-sm font-bold">{{ "Admin.Security.Terrorists.Terrorist_list" | translate }}</span>
    <button
      class="ml-auto w-6"
      (click)="handleDownloadTerroristReportButtonClick()"
      [disabled]="isUserObserverAdmin"
    >
      <app-svg-icon name="import-icon" />
    </button>
  </div> -->

  <table class="adminTable">
    <thead>
      <tr>
        <th scope="col">{{ "Admin.Security.Terrorists.Name" | translate }}</th>
        <th scope="col">{{ "Admin.Security.Terrorists.Last_name" | translate }}</th>
        <th scope="col">{{ "Admin.Security.Terrorists.Middle_name" | translate }}</th>
        <th scope="col">{{ "Admin.Security.Terrorists.PinFL" | translate }}</th>
        <th scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Имя</td>
        <td>Фамилия</td>
        <td>Отчество</td>
        <td>12345</td>
        <td class="flex justify-end">
          <button
            class="h-6 w-6 text-slate-400 hover:text-red-400"
            (click)="openDeleteTerrorModal(0)"
            [disabled]="!hasInteractionPermissions"
          >
            <app-svg-icon name="trash-bin" />
          </button>
        </td>
      </tr>
    </tbody>
  </table>

  <ngb-pagination [collectionSize]="totalCount" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" />
</section>

<ng-template #loader>
  <app-loader></app-loader>
</ng-template>

<jw-modal [id]="ModalConstants.AddTerrorist" (onClose)="handleCloseModal()">
  <form class="flex flex-col space-y-4" [formGroup]="addTerroristForm" (ngSubmit)="onModalSubmit()">
    <h4 class="half-title text-center">{{ "Admin.Security.Terrorists.Add_terrorist" | translate }}</h4>
    <app-input
      [title]="'Admin.Security.Terrorists.Name' | translate"
      type="text"
      formControlName="firstName"
      id="terroristName"
      wrapperClasses="[&>input]:bg-gray-25"
      [errorMsg]="getAddFormErrorMessage('firstName')"
    />
    <app-input
      [title]="'Admin.Security.Terrorists.Last_name' | translate"
      type="text"
      formControlName="lastName"
      id="terroristLastName"
      wrapperClasses="[&>input]:bg-gray-25"
      [errorMsg]="getAddFormErrorMessage('lastName')"
    />
    <app-input
      [title]="'Admin.Security.Terrorists.Middle_name' | translate"
      type="text"
      formControlName="middleName"
      id="terroristMiddleName"
      wrapperClasses="[&>input]:bg-gray-25"
      [errorMsg]="getAddFormErrorMessage('middleName')"
    />
    <app-input
      [title]="'Admin.Security.Terrorists.PinFL' | translate"
      type="text"
      formControlName="pinFL"
      id="terroristPinFL"
      wrapperClasses="[&>input]:bg-gray-25"
      [errorMsg]="getAddFormErrorMessage('pinFL')"
    />
    <button type="submit" class="btn-primary btn-medium" [disabled]="addTerroristForm.invalid">
      {{ "Common.Add" | translate }}
    </button>
  </form>
</jw-modal>

<jw-modal [id]="ModalConstants.AdminDeleteTerror">
  <div class="flex flex-col space-y-4">
    <h4 class="half-title text-center">Удалить террориста</h4>
    <p class="text-center text-slate-400">
      ФИО <br />
      01.01.2025
    </p>
    <div
      class="mx-auto flex max-w-[22.75rem] items-center gap-x-3 rounded-2xl border border-gold-500 px-3 py-2.5 text-sm text-gold-500"
    >
      <app-svg-icon name="trash-bin" class="h-6 w-6" />
      <span>Это значит пользователь сможет совершать операции на платформе</span>
    </div>
    <div class="grid grid-cols-2 gap-x-4">
      <button class="btn-primary-danger btn-medium">Да</button>
      <button class="btn-primary-dark btn-medium" (click)="closeDeleteTerrorModal()">Нет</button>
    </div>
  </div>
</jw-modal>

<jw-modal [id]="ModalConstants.AdminGetScannedTerrors" [bodyWidth]="'w-[44rem]'">
  <span class="text-xl">{{ "Admin.Security.Terrorists.Scanned_terrors_title" | translate }}</span>
  <table class="adminTable mb-3">
    <thead>
      <tr>
        <th scope="col">{{ "Admin.Security.Terrorists.User_fullname" | translate }}</th>
        <th scope="col">{{ "Admin.Security.Terrorists.Terror_fullname" | translate }}</th>
        <th scope="col">{{ "Admin.Security.Terrorists.User_pinFL" | translate }}</th>
        <th scope="col">{{ "Admin.Security.Terrorists.Terror_pinFL" | translate }}</th>
        <th scope="col">{{ "Admin.Security.Terrorists.Block" | translate }}</th>
        <th scope="col">{{ "Admin.Users.Profile" | translate }}</th>
      </tr>
    </thead>
    <tbody *ngIf="scannedTerrorItems?.length; else noTerrors">
      <tr *ngFor="let scannedTerror of scannedTerrorItems">
        <td>{{ scannedTerror.kycFullName }}</td>
        <td>{{ scannedTerror.terroristFullName }}</td>
        <td>{{ scannedTerror.kycPinFL }}</td>
        <td>{{ scannedTerror.terroristPinFL }}</td>
        <td>
          <app-switch
            [(ngModel)]="switchValues[scannedTerror.userId]"
            (onSwitch)="onSwitchChange(scannedTerror.userId)"
          ></app-switch>
        </td>
        <td>
          <a
            class="h-6 w-6"
            [routerLink]="'/admin/users/' + scannedTerror.userId"
            [title]="'Admin.Users.Open_user_page' | translate"
            target="_blank"
          >
            <img src="assets/icons/link.svg" alt="Accept" />
          </a>
        </td>
      </tr>
    </tbody>
    <ng-template #noTerrors>
      <tr>
        <td colspan="7" class="text-center">
          {{ "Admin.Security.Terrorists.Terror_not_found" | translate }}
        </td>
      </tr>
    </ng-template>
  </table>

  <ngb-pagination
    [collectionSize]="totalCount"
    [(page)]="page"
    [pageSize]="pageSize"
    [maxSize]="3"
    [boundaryLinks]="true"
    (pageChange)="requestScannedTerrors()"
  >
  </ngb-pagination>
</jw-modal>

<ngb-toast
  *ngFor="let toast of toastService.toasts"
  [autohide]="true"
  [delay]="toast.delay || 5000"
  (hidden)="toastService.remove(toast)"
>
  <div
    class="mb-2 flex w-fit min-w-[14.25rem] items-center gap-3 rounded-2xl border-min bg-white px-3 py-2.5 text-base shadow-lg dark:border-slate-700 dark:bg-slate-800 max-md:w-full max-md:p-5"
    [ngClass]="{
      'border-green-400 text-green-400': toast.variant === 'success',
      'border-red-400 text-red-400': toast.variant === 'danger',
      'border-gold-400 text-gold-400': toast.variant === 'warning',
      'border-slate-400 text-slate-400': toast.variant === 'info'
    }"
  >
    <app-svg-icon *ngIf="toast.variant === 'success'" name="actions/verified-check" class="w-6" />

    {{ toast.body }}

    <!-- <button (click)="toastService.remove(toast)" class="ml-auto w-5">
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.6 20L4 18.4L10.4 12L4 5.6L5.6 4L12 10.4L18.4 4L20 5.6L13.6 12L20 18.4L18.4 20L12 13.6L5.6 20Z"
          class="fill-slate-400"
        />
      </svg>
    </button> -->
  </div>
</ngb-toast>

<div class="flex items-center justify-between px-3 py-4 max-md:px-2">
  <div class="flex items-center gap-x-4">
    <app-skeleton class="h-12 w-10"></app-skeleton>
    <div class="flex flex-col gap-y-1">
      <app-skeleton class="h-6 w-52 max-md:w-32"></app-skeleton>
      <app-skeleton class="h-6 w-28 max-md:w-16"></app-skeleton>
    </div>
  </div>
  <div class="flex flex-col items-end gap-y-1">
    <app-skeleton class="h-6 w-32 max-md:w-20"></app-skeleton>
    <app-skeleton class="h-6 w-28 max-md:w-16"></app-skeleton>
  </div>
</div>

<div class="relative mx-auto px-6 py-7 max-md:p-0 md:w-[31rem]">
  <div class="mb-2 max-md:mb-3 max-md:flex max-md:items-center max-md:justify-between">
    <button
      (click)="closeModal()"
      class="relative h-8 w-8 max-md:rounded-lg max-md:bg-red-50 dark:max-md:bg-slate-700 md:absolute md:-right-6 md:top-2"
    >
      <app-svg-icon name="close" class="max-md:hidden" />
      <app-svg-icon name="actions/back" class="w-4 text-slate-400 dark:text-white md:hidden" />
    </button>
    <div class="space-y-2 text-center">
      <h1 class="text-xl font-semibold max-md:text-lg">Получить криптовалюту</h1>
      <p class="font-medium text-slate-400 dark:text-slate-200 max-md:hidden">
        Для получения валюты на свой крипто кошелек поделитесь QR кодом или скопируйте код
      </p>
    </div>
    <div class="block h-6 w-6 md:hidden">
      <!-- <app-svg-icon name="info" class="h-8 w-8" /> -->
    </div>
  </div>

  <div class="mb-6 space-y-4 rounded-2xl bg-gray-25 p-4 dark:bg-slate-800 max-md:mb-4 max-md:p-2">
    <ng-container *ngIf="walletBalance !== null && selectedWalletBalance">
      <div class="flex items-start gap-x-2">
        <app-currency-icon [currency]="walletBalance.currency" />
        <div class="flex flex-col gap-y-2 max-md:gap-y-1">
          <span class="font-medium">{{ getCurrencyName(walletBalance.currency) }}</span>
          <span class="font-medium text-slate-400">{{ networkName }}</span>
        </div>
      </div>
    </ng-container>

    <div *ngIf="selectedWalletBalance" class="space-y-2">
      <ng-container *ngIf="walletBalance === null && selectedWalletBalance?.wallet?.address?.length! > 0">
        <span class="mb-1 block">Криптовалюта</span>
        <app-currency-dropdown
          [items]="currencies"
          [isFullWidthList]="true"
          (onSelect)="selectWalletBalanceByCurrency($event)"
        >
          <div
            class="flex items-center gap-x-2 rounded-2xl bg-white px-4 py-2.5 font-medium text-slate-400 dark:border dark:border-slate-400 dark:bg-slate-800 dark:text-white"
          >
            <app-currency-icon *ngIf="selectedCurrency !== null" [currency]="selectedCurrency" size="xs" />

            <span class="flex-1 py-2">{{ selectedCurrencyName }}</span>
            <img src="assets/icons/dropdown-arrow.svg" alt="" class="h-2.5 w-3" />
          </div>
        </app-currency-dropdown>
      </ng-container>

      <!-- Show network dropdown if no preselected balance or if it's abstract usdt -->
      <ng-container
        *ngIf="
          walletBalance === null || (walletBalance && walletBalance.currency === CryptoSymbol.AbstractUsdt)
        "
      >
        <span class="mb-1 block">Сеть</span>
        <app-network-dropdown [items]="networks" [isFullWidthList]="true" (onSelect)="selectNetwork($event)">
          <div
            class="flex items-center rounded-2xl bg-white px-4 py-2.5 font-medium text-slate-400 dark:border dark:border-slate-400 dark:bg-slate-800 dark:text-white"
          >
            <span class="flex-1 py-2">{{ networkName }}</span>
            <img src="assets/icons/dropdown-arrow.svg" alt="" class="h-2.5 w-3" />
          </div>
        </app-network-dropdown>
      </ng-container>
    </div>

    <app-skeleton *ngIf="!selectedWalletBalance" class="h-16" />

    <div class="flex items-start gap-x-2 rounded-[0.625rem] bg-gold-200 p-2 dark:bg-gold-500">
      <img src="assets/icons/warning.svg" alt="" class="h-8 w-8" />
      <span class="text-sm text-slate-400 dark:text-black">
        Отправляйте только активы {{ getCurrencyName(walletBalance?.currency!)
        }}{{ networkName ? "(" + networkName + ")" : "" }} на этот адрес. Остальные активы будут утеряны.
      </span>
    </div>
  </div>

  <div class="flex items-start gap-x-2 max-md:flex-col max-md:gap-y-2">
    <img
      *ngIf="qrBase64; else qrSkeleton"
      class="block w-[9.25rem] dark:bg-white max-md:mx-auto md:w-[12.125rem]"
      [src]="qrBase64"
      alt="QR Code"
    />
    <ng-template #qrSkeleton>
      <app-skeleton class="block aspect-square w-[9.25rem] max-md:mx-auto md:w-[12.125rem]" />
    </ng-template>

    <div class="flex flex-1 flex-col gap-y-2 max-md:w-full">
      <span class="dark:text-slate-200">Адрес кошелька</span>

      <span
        *ngIf="selectedWalletBalance; else walletAddressSkeleton"
        class="w-[15.25rem] break-words font-medium max-md:w-full"
      >
        {{ selectedWalletBalance.wallet?.address }}
      </span>
      <ng-template #walletAddressSkeleton>
        <app-skeleton class="h-12 w-[15.25rem] max-md:w-full" />
      </ng-template>

      <button
        class="btn-primary-dark btn-medium w-fit gap-x-4 max-md:w-full"
        (click)="copyWalletAddress(selectedWalletBalance?.wallet?.address!)"
      >
        <span>Копировать</span>
        <app-svg-icon name="copy" class="h-6 w-6" />
      </button>
    </div>
  </div>

  <ng-container *ngIf="isTelegramMiniApp && isTonNetwork">
    <div class="my-6 flex items-center gap-x-4">
      <div class="h-px flex-1 bg-gray-100 dark:bg-white"></div>
      <span class="dark:text-white">Или</span>
      <div class="h-px flex-1 bg-gray-100 dark:bg-white"></div>
    </div>

    <form
      class="flex flex-col gap-y-2 rounded-2xl bg-gray-25 p-4 dark:bg-slate-800"
      [formGroup]="receiveForm"
      (ngSubmit)="receiveFromTonWallet()"
    >
      <app-input
        [title]="'Send.Amount' | translate"
        type="number"
        name="amount"
        id="amount"
        placeholder="100"
        formControlName="amount"
        appPositiveDecimals
        [errorMsg]="amountError"
      />
      <button
        type="submit"
        [disabled]="receiveForm.invalid || !!amountError"
        class="flex items-center justify-center gap-x-4 rounded-full bg-white px-5 py-2 disabled:cursor-not-allowed disabled:opacity-50"
      >
        <span class="text-center text-[#0A98FD]">
          Получить через Telegram
          <br />
          кошелек
        </span>
        <img src="assets/icons/telegram-wallet.jpg" alt="" class="h-6 w-6 rounded-md" />
      </button>
    </form>
  </ng-container>
</div>

<!-- <div class="flex flex-col gap-y-5">
  <h4 class="underlined-btn underlined-btn_active">{{ "Receive.Title" | translate }}</h4>
  <p>{{ "Receive.Subtitle" | translate: { currency: currencyName, network: networkName } }}</p>

  <hr class="border-border-input-color" />

  <div class="space-y-5">
    <p class="text-center text-xl">{{ "Receive.Select_network" | translate }}</p>
    <div class="grid grid-cols-2 items-center justify-center gap-5">
      <button
        class="rounded-main px-5 py-2 text-2xl font-bold text-text-secondary max-md:text-base"
        [ngClass]="selectedWallet.network === Network.Tron ? 'bg-tron' : 'bg-select-bg'"
        (click)="selectNetwork(Network.Tron)"
      >
        TRON
      </button>
      <button
        class="rounded-main px-5 py-2 text-2xl font-bold text-text-secondary max-md:text-base"
        [ngClass]="selectedWallet.network === Network.Polygon ? 'bg-polygon' : 'bg-select-bg'"
        (click)="selectNetwork(Network.Polygon)"
      >
        Polygon
      </button>
      <button
        class="rounded-main px-5 py-2 text-2xl font-bold text-text-secondary max-md:text-base"
        [ngClass]="selectedWallet.network === Network.Ton ? 'bg-ton' : 'bg-select-bg'"
        (click)="selectNetwork(Network.Ton)"
      >
        Ton
      </button>
      <button
        class="rounded-main px-5 py-2 text-2xl font-bold text-text-secondary max-md:text-base"
        [ngClass]="selectedWallet.network === Network.Bitcoin ? 'bg-bitcoin' : 'bg-select-bg'"
        (click)="selectNetwork(Network.Bitcoin)"
      >
        Bitcoin
      </button>
    </div>
    <p class="flex flex-wrap items-center gap-x-1 max-md:justify-between">
      <span class="text-xl max-md:text-base">{{ "Wallet.Address" | translate }}:</span>
      <a
        [href]="walletLink"
        target="_blank"
        class="max-w-[70%] truncate text-base font-bold text-link-color max-md:order-1 max-md:mb-2 max-md:w-full max-md:max-w-[calc(100vw-6rem)]"
      >
        {{ selectedWallet.address }}
      </a>
      <app-skeleton
        *ngIf="!selectedWallet.address"
        class="w-[70%] max-md:order-1 max-md:mb-2 max-md:w-full"
      />
      <app-copy-btn [value]="selectedWallet.address" />
    </p>
  </div>

  <hr class="border-border-input-color" />

  <img
    *ngIf="qrBase64; else qrSkeleton"
    class="mx-auto block w-[19.375rem] max-md:w-56"
    [src]="qrBase64"
    alt="QR Code"
  />
  <ng-template #qrSkeleton>
    <app-skeleton class="mx-auto block aspect-square w-[19.375rem] max-md:w-56" />
  </ng-template>

  <hr class="border-border-input-color" />

  <app-balance-card
    *ngFor="let balance of selectedWallet.balances"
    [isShort]="true"
    [balanceItem]="balance"
    [withSelect]="isTelegramMiniApp && selectedWallet.network === Network.Ton"
    [selected]="balance.currency === selectedWalletBalance?.currency"
    (onSelect)="selectWalletBalance($event)"
  />

  <ng-container *ngIf="isTelegramMiniApp && selectedWallet.network === Network.Ton">
    <hr class="my-5 border-border-input-color" />
    <form class="flex flex-col gap-y-5" [formGroup]="receiveForm" (ngSubmit)="receiveFromTonWallet()">
      <app-input
        [title]="'Send.Amount' | translate"
        type="number"
        name="amount"
        id="amount"
        placeholder="100"
        formControlName="amount"
        appPositiveDecimals
        [errorMsg]="amountError"
      />
      <button
        type="submit"
        [disabled]="!isTonWalletConnected || receiveForm.invalid || !!amountError"
        class="flex items-center gap-x-4 rounded-2xl bg-input-bg p-4 disabled:cursor-not-allowed disabled:opacity-50"
        (click)="receiveFromTonWallet()"
      >
        <img src="assets/images/ton-wallet-medium.png" alt="" class="w-9" />
        <div>
          <h4 class="!m-0 !mb-1 font-semibold text-ton">Получить через "Кошелек"</h4>
          <p class="!m-0 text-sm opacity-35">Откроет приложение "Кошелек"</p>
        </div>
      </button>
    </form>
    <p *ngIf="!isTonWalletConnected" class="flex items-center justify-between text-text-pale">
      Telegram кошелек не подключен
    </p>
    <hr class="my-5 border-border-input-color" />
  </ng-container>

  <button class="btn w-full" (click)="closeModal()">{{ "Common.Close" | translate }}</button>

  <hr class="border-border-input-color" />
  <p>{{ "Receive.Commission" | translate: { network: networkName } }}</p>
</div> -->

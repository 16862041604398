<div class="box h-full p-5">
  <div class="flex items-center justify-between border-b border-gray-100 pb-5">
    <h3 class="altitle">{{ "Admin.Home.Commission" | translate }}</h3>
    <button
      *ngIf="hasInteractionPermissions"
      class="w-5 text-slate-400 hover:text-black"
      (click)="openCreateModal(selectedCurrency, createCommissionModal)"
    >
      <app-svg-icon name="plus" />
    </button>
  </div>

  <div class="mt-5 space-y-4">
    <p class="text-sm font-medium text-slate-400 dark:text-slate-200">Комиссия за продажу активов:</p>
    <ng-container *ngFor="let item of getCommissions(selectedCurrency)">
      <div class="flex items-center">
        <p class="text-sm font-medium">
          <span class="text-sm text-slate-400">
            {{
              "Admin.Home.Up_to"
                | translate
                  : {
                      value: item.maxAmount | mfeCustom: { currency: selectedCurrency },
                      ticker: currencyName
                    }
            }}
          </span>
          <span>{{ item.percents }}%</span>
        </p>
        <button
          *ngIf="hasInteractionPermissions"
          class="ml-auto w-5 text-slate-400 hover:text-red-500"
          (click)="openDeleteModal(item, deleteCommissionModal)"
        >
          <app-svg-icon name="settings/trash" />
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="getCommissions(selectedCurrency).length === 0">
      <p class="text-xs font-medium text-slate-400 dark:text-slate-200">Не установлена</p>
    </ng-container>
  </div>

  <div class="mt-8 space-y-4">
    <p class="text-sm font-medium text-slate-400 dark:text-slate-200">Комиссия за вывод средств:</p>
    <ng-container *ngFor="let item of getWithdrawCommissions(selectedCurrency)">
      <div class="flex items-center">
        <p class="text-sm font-medium">{{ item.amount }}%</p>
        <button
          *ngIf="hasInteractionPermissions"
          class="ml-auto w-5 text-slate-400 hover:text-black"
          (click)="openEditWithdrawCommissionModal(item.id, item.amount, editWithdrawCommissionModal)"
        >
          <app-svg-icon name="edit" />
        </button>
      </div>
    </ng-container>
  </div>

  <div class="mt-8 space-y-4">
    <p class="text-sm font-medium text-slate-400 dark:text-slate-200">Комиссия за обмен средств на USDT:</p>
    <ng-container *ngFor="let item of getSwapCommissions(selectedCurrency)">
      <div class="flex items-center">
        <p class="text-sm font-medium">{{ item.percent }}%</p>
        <button
          *ngIf="hasInteractionPermissions"
          class="ml-auto w-5 text-slate-400 hover:text-black"
          (click)="openEditSwapCommissionModal(item.percent, editSwapCommissionModal)"
        >
          <app-svg-icon name="edit" />
        </button>
      </div>
    </ng-container>
  </div>
</div>

<ng-template let-modal #createCommissionModal>
  <form class="flex flex-col space-y-6 px-15 py-8" [formGroup]="form" (ngSubmit)="onCreateCommissionSubmit()">
    <h4 class="text-center text-2xl font-semibold">{{ "Admin.Home.Commission" | translate }}</h4>
    <app-input
      [title]="('Admin.Home.Check_sum' | translate) + ' ' + currencyName"
      type="number"
      placeholder="0"
      formControlName="maxAmount"
      [errorMsg]="amountError"
      wrapperClasses="[&>input]:bg-gray-25"
    />
    <app-input
      [title]="('Admin.Home.Commission' | translate) + ' %'"
      type="number"
      placeholder="0"
      formControlName="percent"
      [errorMsg]="percentError"
      wrapperClasses="[&>input]:bg-gray-25"
    />
    <button
      type="submit"
      class="btn-primary btn-medium"
      [disabled]="form.invalid || isFormPending || !!amountError"
    >
      {{ "Admin.Home.Add" | translate }}
    </button>
  </form>
</ng-template>

<ng-template let-modal #editWithdrawCommissionModal>
  <form
    class="flex flex-col space-y-6 px-15 py-8"
    [formGroup]="editWithdrawForm"
    (ngSubmit)="onEditWithdrawSubmit()"
  >
    <h4 class="text-center text-2xl font-semibold">Комиссия за вывод {{ currencyName }}</h4>
    <app-input
      [title]="('Admin.Home.Commission' | translate) + ' %'"
      type="number"
      placeholder="0"
      formControlName="percent"
      [errorMsg]="editWithdrawPercentError"
      wrapperClasses="[&>input]:bg-gray-25"
    />
    <button
      type="submit"
      class="btn-primary btn-medium"
      [disabled]="editWithdrawForm.invalid || isEditWithdrawPending"
    >
      {{ "Common.Confirm" | translate }}
    </button>
  </form>
</ng-template>

<ng-template #deleteCommissionModal let-modal>
  <div class="flex flex-col space-y-6 px-15 py-8 text-center">
    <h4 class="half-title">Удалить комиссию</h4>
    <p>Вы уверены, что хотите удалить комиссию?</p>
    <p class="text-sm font-medium">
      <span class="text-sm text-slate-400">
        {{
          "Admin.Home.Up_to"
            | translate
              : {
                  value:
                    commissionToDelete?.maxAmount | mfeCustom: { currency: commissionToDelete?.currency },
                  ticker: currencyName
                }
        }}
      </span>
      <span>{{ commissionToDelete?.percents }}%</span>
    </p>
    <div class="grid grid-cols-2 gap-x-4">
      <button class="btn-primary-danger btn-medium" (click)="deleteCommission(commissionToDelete?.id!)">
        {{ "Common.Yes" | translate }}
      </button>
      <button class="btn-primary-dark btn-medium" (click)="modal.close()">
        {{ "Common.No" | translate }}
      </button>
    </div>
  </div>
</ng-template>

<ng-template let-modal #editSwapCommissionModal>
  <form class="flex flex-col space-y-6 px-15 py-8" [formGroup]="editSwapForm" (ngSubmit)="onEditSwapSubmit()">
    <h4 class="text-center text-2xl font-semibold">Комиссия за обмен {{ currencyName }} на USDT</h4>
    <app-input
      [title]="('Admin.Home.Commission' | translate) + ' %'"
      type="number"
      placeholder="0"
      formControlName="percent"
      [errorMsg]="editSwapPercentError"
      wrapperClasses="[&>input]:bg-gray-25"
    />
    <button
      type="submit"
      class="btn-primary btn-medium"
      [disabled]="editSwapForm.invalid || isEditSwapPending"
    >
      {{ "Common.Confirm" | translate }}
    </button>
  </form>
</ng-template>

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { ModalConstants } from "src/app/common/constants/modal-ids.constants";
import { CreateRoleDto, RoleDto, UpdateRoleDto } from "src/app/common/DTO/roles";
import { AdminPermission } from "src/app/common/enums/admin-permission.enum";
import { RolesErrorCode } from "src/app/common/enums/roles-error-code.enum";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { RolesService } from "src/app/services/roles.service";
import { ToastService } from "src/app/services/toast.service";
import { ModalService } from "../../_modal";

@Component({
  selector: "app-roles-block",
  templateUrl: "./roles-block.component.html",
  styleUrls: ["./roles-block.component.css"],
})
export class RolesBlockComponent implements OnInit {
  @Input() public roles: RoleDto[] = [];

  @Output() public onRolesChange = new EventEmitter();

  public permissionsList = [
    { name: "Платформа", value: AdminPermission.PlatformInteraction },
    { name: "Пользователи", value: AdminPermission.UserInteraction },
    { name: "Комиссии", value: AdminPermission.CommissionInteraction },
    { name: "Транзакции", value: AdminPermission.TransactionInteraction },
    { name: "Администраторы", value: AdminPermission.AdminInteraction },
    { name: "Удаление", value: AdminPermission.DeleteAdmin },
  ];
  public addPermissionsList = this.permissionsList.filter(
    permission => permission.value !== AdminPermission.DeleteAdmin
  );

  public AdminPermission = AdminPermission;
  public ModalConstants = ModalConstants;
  public hasInteractionPermissions: boolean = false;
  public addRoleForm: FormGroup = new FormGroup({});
  public isPending: boolean = false;

  constructor(
    private readonly _localStorage: LocalStorageService,
    private readonly _translateService: TranslateService,
    private readonly _toastService: ToastService,
    private readonly _rolesService: RolesService,
    private readonly _modalService: ModalService
  ) {
    this.addRoleForm = new FormGroup({
      name: new FormControl("", [Validators.required]),
      permissions: new FormControl([], [Validators.required]),
    });
  }

  async ngOnInit(): Promise<void> {
    this.hasInteractionPermissions = await this._localStorage.adminHasPermission(
      AdminPermission.AdminInteraction
    );
    if (!this.roles.length) {
      await this.requestRoles();
    }
  }

  public canPermissionBeChanged(role: RoleDto, permission: AdminPermission) {
    if (
      role.name === "SuperAdmin" ||
      permission === AdminPermission.DeleteAdmin ||
      !this.hasInteractionPermissions
    ) {
      return false;
    }
    return true;
  }

  public getSwitchIsChecked(roleId: number, permission: AdminPermission) {
    return this.roles.find(role => role.id === roleId)?.permissions.includes(permission);
  }

  public async handleSwitchChange(checked: boolean, roleId: number, permission: AdminPermission) {
    const role = this.roles.find(role => role.id === roleId);
    const permissions = role?.permissions ?? [];
    if (checked) {
      permissions.push(permission);
    } else {
      permissions.splice(permissions.indexOf(permission), 1);
    }
    const dto: UpdateRoleDto = {
      roleId,
      newPermissions: permissions,
    };
    this.isPending = true;
    await this.updateRole(dto);
    this.isPending = false;
  }

  public getSwitchOnAddIsChecked(permission: AdminPermission) {
    return this.addRoleForm.value.permissions?.includes(permission);
  }

  public onChangeSwitchOnAdd(permission: AdminPermission) {
    const permissions = this.addRoleForm.value.permissions;
    if (this.getSwitchOnAddIsChecked(permission)) {
      permissions.splice(permissions.indexOf(permission), 1);
    } else {
      permissions.push(permission);
    }
    this.addRoleForm.controls["permissions"].setValue(permissions);
  }

  public async onSubmitAddRole() {
    const dto: CreateRoleDto = {
      name: this.addRoleForm.value.name as string,
      permissions: this.addRoleForm.value.permissions as AdminPermission[],
    };
    this.isPending = true;
    await this.addRole(dto);
    this.isPending = false;
    this.clearForms();
    this._modalService.close(ModalConstants.AdminAddRole);
    this.onRolesChange.emit();
  }

  public async handleDeleteRole(roleId: number) {
    this.isPending = true;
    await this.deleteRole(roleId);
    this.isPending = false;
    this.onRolesChange.emit();
  }

  public clearForms() {
    this.addRoleForm.reset({ name: "", permissions: [] });
  }

  private async requestRoles() {
    const res = await this._rolesService.getAllRoles();
    this.roles = res.params ?? [];
  }

  private async updateRole(dto: UpdateRoleDto) {
    const res = await this._rolesService.updateRole(dto);

    if (res.withError) {
      switch (res.errorCode) {
        case RolesErrorCode.NoPermissions:
          this._toastService.error(this._translateService.instant("Admin.Admins.Role_no_permissions"));
          break;
        default:
          this._toastService.error(this._translateService.instant("Admin.Admins.Update_role_error"));
          break;
      }
    } else {
      const msg = this._translateService.instant("Admin.Admins.Update_role_success");
      this._toastService.success(msg);
    }
    this.requestRoles();
  }

  private async addRole(dto: CreateRoleDto) {
    const res = await this._rolesService.createRole(dto);

    if (res.withError) {
      switch (res.errorCode) {
        case RolesErrorCode.NoPermissions:
          this._toastService.error(this._translateService.instant("Admin.Admins.Role_no_permissions"));
          break;
        default:
          this._toastService.error(this._translateService.instant("Admin.Admins.Update_role_error"));
          break;
      }
    } else {
      const msg = this._translateService.instant("Admin.Admins.Add_role_success");
      this._toastService.success(msg);
      this.requestRoles();
    }
  }

  private async deleteRole(roleId: number) {
    const res = await this._rolesService.deleteRole(roleId);

    if (res.withError) {
      switch (res.errorCode) {
        case RolesErrorCode.NoPermissions:
          this._toastService.error(this._translateService.instant("Admin.Admins.Role_no_permissions"));
          break;
        default:
          this._toastService.error(this._translateService.instant("Admin.Admins.Delete_role_error"));
          break;
      }
    } else {
      const msg = this._translateService.instant("Admin.Admins.Delete_role_success");
      this._toastService.success(msg);
      this.requestRoles();
    }
  }
}

import { Component, OnInit } from "@angular/core";
import { AdminPermission } from "src/app/common/enums/admin-permission.enum";
import { LocalStorageService } from "src/app/services/local-storage.service";

@Component({
  selector: "app-admin-sidebar",
  templateUrl: "./admin-sidebar.component.html",
  styleUrls: ["./admin-sidebar.component.css"],
})
export class AdminSidebarComponent implements OnInit {
  private readonly _sidebarLinks = [
    {
      title: "Admin.Sidebar.Home",
      link: "/admin/home",
      icon: "admin/home",
      permission: AdminPermission.PlatformInteraction,
    },
    {
      title: "Admin.Sidebar.Transactions",
      link: "/admin/transactions",
      icon: "admin/transactions",
      permission: AdminPermission.TransactionInteraction,
    },
    {
      title: "Admin.Sidebar.Users",
      link: "/admin/users",
      icon: "admin/users",
      permission: AdminPermission.UserInteraction,
    },
    {
      title: "Admin.Sidebar.Admins",
      link: "/admin/admins",
      icon: "admin/admins",
      permission: AdminPermission.AdminInteraction,
    },
    {
      title: "Admin.Sidebar.Reports",
      link: "/admin/reports",
      icon: "admin/document",
      permission: AdminPermission.PlatformInteraction,
    },
    {
      title: "Admin.Sidebar.Security",
      link: "/admin/security",
      icon: "admin/bomb",
      permission: AdminPermission.PlatformInteraction,
    },
  ];

  private _visibleLinks: typeof this._sidebarLinks = [];

  constructor(private readonly _localStorage: LocalStorageService) {}

  get sidebarLinks() {
    return this._visibleLinks;
  }

  async ngOnInit() {
    await this.filterLinksByPermissions();
  }

  private async filterLinksByPermissions(): Promise<void> {
    const permissionChecks = await Promise.all([
      this._localStorage.adminHasPermission(AdminPermission.TransactionInteraction),
      this._localStorage.adminHasPermission(AdminPermission.UserInteraction),
      this._localStorage.adminHasPermission(AdminPermission.AdminInteraction),
      this._localStorage.adminHasPermission(AdminPermission.PlatformInteraction),
    ]);

    const [canViewTransactions, canViewUsers, canViewAdmins, canViewPlatform] = permissionChecks;

    this._visibleLinks = this._sidebarLinks.filter(link => {
      if (!link.permission) return true;

      switch (link.permission) {
        case AdminPermission.TransactionInteraction:
          return canViewTransactions;
        case AdminPermission.UserInteraction:
          return canViewUsers;
        case AdminPermission.AdminInteraction:
          return canViewAdmins;
        case AdminPermission.PlatformInteraction:
          return canViewPlatform;
        default:
          return false;
      }
    });
  }
}
